.pressebericht {
  margin-top: 200px;

  @media (max-width: 1025px) {
    margin-top: 120px;
  }

  @media (max-width: 480px) {
    margin-top: 100px;
  }

  .container.flex {
    flex-direction: column;

    .image {

      @media (max-width: 1024px) {
        margin: 0 -40px;
      }

      @media (max-width: 600px) {
        margin: 0 -30px;
      }

      @media (max-width: 480px) {
        margin: 0 -25px;
      }

      img {
        max-height: 50vh;
        object-fit: cover;
      }
    }

    .image-description {

      p {
        font-size: 20px;
      }
    }

    .datetime {
      display: flex;
      flex-direction: row;
      margin-top: 80px;
      text-transform: uppercase;

      @media (max-width: 480px) {
        margin-top: 40px;
      }

      .intro {
        font-weight: 700;
      }

      p {
        margin-bottom: 0;
      }
    }

    .headline {

      h1 {

        @media (max-width: 1024px) {
          font-size: 4.5rem;
          line-height: 130%;
        }

        @media (max-width: 600px) {
          font-size: 3.5rem;
        }

        @media (max-width: 480px) {
          margin-top: 0;
        }
      }

      h1:before,
      h1:after {
        display: none;
      }
    }

    .text {
      margin-bottom: 48px;
    }

    .teaser {
      margin-bottom: 20px;
    }

    .linktxt {
      font-size: 25px;

      @media (max-width: 1024px) {
        font-size: 2.2rem;
      }

      @media (max-width: 850px) {
        font-size: 1.8rem;
      }

      @media (max-width: 600px) {
        font-size: 1.6rem;
      }
    }

    .kontakt {
      margin-top: 150px;

      @media (max-width: 850px) {
        margin-top: 100px;
      }

      .contact-headline {
        text-transform: uppercase;
        font-size: 65px;
        font-weight: 400;
        line-height: 85px;
        margin-bottom: 48px;
      }

      .kontakt-box {
        display: flex;
        column-gap: 60px;

        @media (max-width: 1680px) {
          flex-direction: column;
        }

        .text-links {

          @media (max-width: 1680px) {
            width: 100%;
            margin-bottom: 60px;
          }
        }

        .kontakt-card {
          display: flex;
          max-height: 50vh;

          @media (max-width: 1680px) {
            width: 100%;
          }

          @media (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 150px;
          }

          .imagebox {

            @media (max-width: 1680px) {
              width: 50%;
            }

            @media (max-width: 768px) {
              margin-bottom: -5px;
              width: 100%;
            }

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: top;

              @media (max-width: 768px) {
                max-height: 50vh;
                width: 100%;
              }
            }
          }
        }

        .textbox {
          margin-top: 0;
          margin-bottom: 0;
          padding-bottom: 50px;

          @media (max-width: 1680px) {
            width: 50%;
          }

          @media (max-width: 768px) {
            width: 100%;
          }

          p {
            line-height: 1.8;
            margin-bottom: 0;

            strong {

              &:first-child {
                color: #FCB21F;
                font-size: 3rem;
                line-height: normal;
                text-transform: uppercase;
              }
            }
          }

          p.flex-start {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .mediencontainer {
    background: #EAF5F5;
    padding-top: 104px;
    padding-bottom: 104px;

    .container.flex.medien {

      .headline {
        text-transform: uppercase;
        font-weight: 700;
      }

      .file-container {
        width: 100%;

        a.file {
          width: 100%;
          display: block;
          float: left;
          position: relative;
          cursor: pointer;
          margin-top: 20px;
          border: 1px solid #535D64;
          color: inherit;
          text-align: left;
          background: transparent;
          padding: 25px 25px 25px 40px;
          hyphens: auto;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;

          &:hover {
            outline: 0;

            .dl-ov {
              width: 100%;
            }
          }

          &::after {
            content: "";
            background: url("assets/img/Icon material-file-download.svg");
            display: inline-block;
            float: right;
            width: 21px;
            height: 25px;
            position: absolute;
            top: 30%;
            right: 20px;
          }

          .dl-ov {
            height: 100%;
            width: 25px;
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            transition: 0.8s;
            transition-timing-function: ease-out;
          }

          .dl-name {
            position: relative;
            z-index: 10;
            font-size: 25px;
          }
        }
      }
    }
  }
}
