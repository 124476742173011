.hero-video {
  video {
    width: 100%;
    max-height: 85vh;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
  }

  .galoverlay {
    position: absolute;
    z-index: 9;
    left: 10%;
    bottom: 15%;

    p {
      @include font-size($fontsize-XL);
      line-height: 1.44;
      color: $white;

      strong {
        line-height: 1.25;
        @include font-size($fontsize-XXL);

        &:first-child {
          font-weight: 400;
        }
      }
    }
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {
  .hero-video {
    .galoverlay {
      p {
        @include font-size($fontsize-L2);

        strong {
          @include font-size($fontsize-XL60);
        }
      }
    }
  }
}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
  .hero-video {
    video {
      height: 400px;
    }

    .galoverlay {
      left: 5%;
    }
  }
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
  .hero-video {
    video {
      height: 400px;
    }

    .galoverlay {
      top: 160px;
      max-width: 90%;

      p {
        @include font-size($fontsize-20);

        strong {
          @include font-size($fontsize-L2);
        }
      }

      .hero-slider-link {
        a.linkbtn {
          padding: 15px 30px;
        }
      }
    }
  }
}
@media all and (max-width:$size-S) {}

.page-id-8 .hero-video .eeg-stoerer {
  /*position: absolute;
  bottom: 50px;
  left: 900px;*/
  z-index: 9999;
  position: absolute;
  right: 10%;
  bottom: 20%;
  width: 215px;
  height: auto;
  -o-object-fit: unset !important;
  object-fit: unset !important;
  min-height: auto !important;
  -webkit-transform: rotate(14deg);
  transform: rotate(14deg);

  @media (max-width: 1280px) {
    /*bottom: 50px;
    left: 650px;*/
    width: 180px;
  }
  /*@media (max-width: 1180px) {
    bottom: 100px;
    left: 581px;
    width: 23%;
  }*/
  @media (max-width: 1024px) {
    width: 150px;
    right: 3%;
    bottom: 12%;
  }
  /*@media (max-width: 820px) {
    bottom: 130px;
    left: 450px;
    width: 30%;
  }*/
  @media (max-width: 768px) {
    width: 120px;
  }
  /*@media (max-width: 700px) {
    top: -25px;
    left: 335px;
  }*/
  /*@media (max-width: 690px) {
    top: -25px;
    left: 350px;
  }*/
  /*@media (max-width: 615px) {
    top: -5px;
    left: 300px;
  }*/
  /*@media (max-width: 560px) {
    top: -20px;
    left: 300px;
  }*/
  /*@media (max-width: 520px) {
    top: -30px;
    left: 250px;
  }*/
  @media (max-width: 480px) {
    bottom: 9%;
  }
  /*@media (max-width: 420px) {
    top: -70px;
    left: 195px;
  }*/
  /*@media (max-width: 375px) {
    top: -65px;
    left: 170px;
  }*/
}

.page-id-9504,
.page-id-7924 {

  .hero-video {

    .galoverlay {
      bottom: 10%;

      @media (max-width: 600px) {
        top: unset;
      }

      p {
        font-size: 4.5rem;

        @media (max-width: 1280px) {
          font-size: 3rem;
        }

        @media (max-width: 600px) {
          font-size: 2rem;
        }

        strong {
          font-size: 10.5rem;

          @media (max-width: 1280px) {
            font-size: 6rem;
          }

          @media (max-width: 600px) {
            font-size: 3rem;
          }
        }
      }

      .hero-slider-link {

        a.linkbtn {
          padding: 20px 50px;

          @media (max-width: 1280px) {
            font-size: 2.0rem;
            padding: 15px 20px;
          }

          @media (max-width: 600px) {
            font-size: 1.5rem;
            padding: 10px 15px;
          }
        }
      }
    }
  }
}
