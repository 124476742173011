/* Kontakt */
.kontaktform {
  input.wpcf7-text,
  select,
  textarea {
    padding: 10px 20px;
    box-sizing: border-box;
    width: 100%;
    margin-right: 16px;
    border: 1px solid $green;
    margin-bottom: 16px;
    outline: none;
  }

  input[type="checkbox"] {
    float: left;
  }

  .wpcf7-submit {
    background: $green;
    border: 0;
    padding: 10px 20px;
    color: $white;
  }

  .wpcf7-submit:hover {
    background: $turkis;
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  .kontaktform .g-6.centered {
    width: 74.528%;
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
  .kontaktform .g-6.centered {
    width: 100%;
  }
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
