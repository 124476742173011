.pressebericht-neueste {
  margin-top: 200px;
  margin-bottom: 200px;

  @media (max-width: 850px) {
    margin-top: 150px;
    margin-bottom: 150px;
  }

  p.subheading {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 700;
  }

  .presseheadline h1,
  .presseheadline h2,
  .presseheadline h3,
  .presseheadline h4,
  .presseheadline h5,
  .presseheadline h6 {

    @media (max-width: 480px) {
      margin-top: 0;
    }
  }

  .post-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media (max-width: 1025px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 420px) {
      display: block;
    }

    .post-item {
      grid-column: span 1;
      background: #EAF5F5;

      @media (max-width: 420px) {
        margin-bottom: 16px;
      }

      .post-image {
        height: 335px;
        width: 100%;
        object-fit: cover;

        @media (max-width: 768px) {
          height: auto;
        }
      }

      .entry-content {
        padding: 39px;
        background: #EAF5F5;
        margin-top: -5px;

        h2 {
          line-height: 0;

          a {
            font-size: 2.5rem;
            font-weight: 700;
            line-height: normal;
            color: #000000;
            text-transform: uppercase;

            @media (max-width: 420px) {
              width: 100%;
            }
          }
        }

        p.date {
          font-size: 20px;
          margin-bottom: 0;
        }

        .readmore {
          font-size: 25px;
          font-weight: 700;

          @media (max-width: 1024px) {
            font-size: 2.2rem;
          }

          @media (max-width: 850px) {
            font-size: 1.8rem;
          }

          @media (max-width: 600px) {
            font-size: 1.6rem;
          }
        }
      }

      &:first-of-type {
        display: grid;
        grid-column: span 3;
        grid-template-columns: 2fr 1fr;
        gap: 9px;

        @media (max-width: 1025px) {
          grid-column: span 2;
          grid-template-columns: 1fr;
        }

        @media (max-width: 768px) {
          grid-column: span 1;
        }

        @media (max-width: 420px) {
          display: block;
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;

          @media (max-width: 768px) {
            height: auto;
            width: 100%;
          }
        }

        .entry-content {
          display: grid;
          grid-template-columns: 1fr;
          align-content: center;
          margin-top: 0;
          height: unset;

          @media (max-width: 420px) {
            display: block;
            padding: 39px;
            background: #EAF5F5;
            margin-top: -5px;
          }

          .title {

            a {

              @media (max-width: 420px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .button-container {
    text-align: center;
    margin-top: 48px;
  }

  .kontakt {
    margin-top: 150px;

    @media (max-width: 850px) {
      margin-top: 100px;
    }

    .contact-headline {
      text-transform: uppercase;
      font-size: 65px;
      font-weight: 400;
      line-height: 85px;
      margin-bottom: 48px;
    }

    .kontakt-box {
      display: flex;
      column-gap: 60px;

      @media (max-width: 1680px) {
        flex-direction: column;
      }

      .text-links {

        @media (max-width: 1680px) {
          width: 100%;
          margin-bottom: 60px;
        }
      }

      .kontakt-card {
        display: flex;
        max-height: 50vh;

        @media (max-width: 1680px) {
          width: 100%;
        }

        @media (max-width: 768px) {
          flex-direction: column;
          margin-bottom: 150px;
        }

        .imagebox {

          @media (max-width: 1680px) {
            width: 50%;
          }

          @media (max-width: 768px) {
            margin-bottom: -5px;
            width: 100%;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top;

            @media (max-width: 768px) {
              max-height: 50vh;
              width: 100%;
            }
          }
        }
      }

      .textbox {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 50px;

        @media (max-width: 1680px) {
          width: 50%;
        }

        @media (max-width: 768px) {
          width: 100%;
        }

        p {
          line-height: 1.8;
          margin-bottom: 0;

          strong {

            &:first-child {
              color: #FCB21F;
              font-size: 3rem;
              line-height: normal;
              text-transform: uppercase;
            }
          }
        }

        p.flex-start {
          margin-bottom: 0;
        }
      }
    }
  }
}
