/* Formulare */
.gform_wrapper {
  margin-top: 50px;

  .gform_page_footer {
    margin-top: 25px !important;
  }
}

.gform_wrapper.gravity-theme .gfield {
  input,
  textarea {
    border: 3px solid transparent;
    padding: 15px !important;
  }
}

.gform_wrapper.gravity-theme .gfield {
  input:focus,
  textarea:focus {
    border: 3px solid $green;
    outline: 0;
  }
}

.gform_wrapper.gravity-theme .gform_footer button,
.gform_wrapper.gravity-theme .gform_footer input,
.gform_wrapper.gravity-theme .gform_page_footer button,
.gform_wrapper.gravity-theme .gform_page_footer input {
  @include font-size($fontsize-L);
  position: relative;
  font-weight: 600;
  padding: 15px 50px;
  border-radius: 15px;
  transition: all 0.5s ease-in-out;
  background: $green;
  color: $white;
  border: 0;
}

.gform_wrapper.gravity-theme .gform_footer button:hover,
.gform_wrapper.gravity-theme .gform_footer input:hover,
.gform_wrapper.gravity-theme .gform_page_footer button:hover,
.gform_wrapper.gravity-theme .gform_page_footer input:hover {
  color: $white;
  background: $yellow;
}

/* Anpassungen für Kontaktformulare
  PV-Anlage
  + Wallbox
  + Direktvermarktung
  + Gewerbestrom
  + Post-EEG
  + Power-to-heat
  + PV-Stromspeicher
  + Service Einzug
  + Service Daten ändern
  + Service Elektronische Rechnung
  + ÜZW Strassenbeleuchtung
  + Vertragskündigung
*/
.page-id-3183 #gform_wrapper_2 .gform_heading,
.page-id-3195 #gform_wrapper_3 .gform_heading {
  display: none;
}

.page-id-3183 .pflichtfeldangabe p,
.page-id-3195 .pflichtfeldangabe p {
  font-size: 1.6rem;
}

.page-id-3183 #gform_2,
.page-id-3195 #gform_3,
.page-id-3204 #gform_1,
.page-id-3209 #gform_4,
.page-id-3214 #gform_13,
.page-id-3219 #gform_10,
.page-id-3294 #gform_5,
.page-id-3303 #gform_6,
.page-id-3224 #gform_9,
.page-id-3328 #gform_11,
.page-id-5306 #gform_15,
.page-id-6036 #gform_16 {
  width: 100%;

  .gfield_label {
    color: #8BADAD;
    font-weight: 400;
  }

  .pflichtfeldangabe p {
    font-size: 1.6rem;
  }

  .gform_footer {
    margin-top: 25px;
  }
}

.page-id-3183 #gform_fields_2,
.page-id-3195 #gform_fields_3,
.page-id-3204 #gform_fields_1,
.page-id-3204 #gform_fields_1,
.page-id-3209 #gform_fields_4,
.page-id-3214 #gform_fields_1,
.page-id-3219 #gform_fields_10,
.page-id-3294 #gform_fields_5,
.page-id-3303 #gform_fields_6,
.page-id-3224 #gform_fields_9,
.page-id-3328 #gform_fields_11,
.page-id-5306 #gform_fields_15,
.page-id-6036 #gform_fields_16 {
  background: #EAF5F5;
  padding: 40px;

  #field_2_26,
  #field_3_26,
  #field_4_26,
  #field_5_26,
  #field_6_26,
  #field_9_26,
  #field_10_26,
  #field_11_26,
  #field_13_26,
  #field_15_26,
  #field_16_26 {
    label {
      margin-left: 22px;
      margin-top: -21px;
      display: inline-block;
    }
  }
}

.page-id-6036 .spacer.gfield,
.page-id-5306 .gform_heading {
  display: none;
}

.page-id-3195 .gfield_required,
.page-id-3204 .gfield_required,
.page-id-3209 .gfield_required,
.page-id-3214 .gfield_required,
.page-id-3219 .gfield_required,
.page-id-3224 .gfield_required,
.page-id-3303 .gfield_required,
.page-id-3328 .gfield_required,
.page-id-3294 .gfield_required,
.page-id-5306 .gfield_required {
  display: none !important;
}

/* Ende Anpassungen für Kontaktformulare */

/* Start Anpassungen für Bestellstrecke */
#gform_17 legend {
  font-size: 1.6rem;
  font-weight: bold !important;
  line-height: 20px;
  color: #1c1c1c !important;
  margin-bottom: 15px;
}

#gform_17 #field_17_145,
#gform_17 #field_17_148 {
  grid-column: 1/3;
}

#gform_17 #field_17_147,
#gform_17 #field_17_149 {
  display: none;
}

#gform_17 #field_17_148,
#gform_17 #field_17_145 {
  label {
    color: #1c1c1c !important;
    font-size: 1.6rem;
    font-weight: bold !important;
    line-height: 20px;
  }
}

#gform_17 #field_17_140,
#gform_17 #field_17_143 {
  grid-column: 1/3;
}
#gform_17 #field_17_142,
#gform_17 #field_17_144 {
  display: none;
}

#gform_17 .spacer.gfield {
  display: none;
}

#gform_17 #field_17_140,
#gform_17 #field_17_143 {
  label {
    color: #1c1c1c !important;
    font-size: 1.6rem;
    font-weight: bold !important;
    line-height: 20px;
  }
}

/* Ende Anpassungen für Bestellstrecke */

@media all and (max-width: $size-XXL) {
}

@media all and (max-width: $size-XL2) {
}

@media all and (max-width: $size-XL) {
}

@media all and (max-width: $size-L) {
}

@media all and (max-width: $size-M2) {
}

@media all and (max-width: $size-M) {
}

@media all and (max-width: $size-S2) {
}

@media all and (max-width: $size-S) {
}

@media all and (max-width: $size-XS2) {
}

@media all and (max-width: $size-XS) {
}
