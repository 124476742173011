/* News */
.news-section {
  hyphens: auto;

  .date {
    color: $bluegrey;
    @include font-size($fontsize-M);
  }

  .g-6 a,
  .news-archiv a {
    text-transform: uppercase;
    color: $black;
    font-weight: 700;
  }

  .news-featured {
    h3 {
      @include font-size($fontsize-XL);
      line-height: 6rem;
    }
  }

  .bigoutline::before {
    content: "";
    display: inline-block;
    height: 1px;
    background: $bluegrey;
    position: absolute;
    width: 2000px;
    left: -2030px;
    top: 20px;
  }

  .flex {
    align-items: flex-start;
  }
}

@media all and (max-width: $size-XXL) {
}

@media all and (max-width: $size-XL2) {
}

@media all and (max-width: $size-XL) {
}

@media all and (max-width: $size-L) {
  .news-section {
    article:not(last-child) {
      margin-bottom: 50px;
    }

    img {
      max-height: 420px;
    }

    .g-6 h3 {
      @include font-size($fontsize-L);
      line-height: 3.5rem;
      margin-top: 30px;
    }

    .text-align-right {
      text-align: left;
      margin-bottom: 10px;
    }
  }
}

@media all and (max-width: $size-M2) {
}

@media all and (max-width: $size-M) {
}

@media all and (max-width: $size-S2) {
}

@media all and (max-width: $size-S) {
}

@media all and (max-width: $size-XS2) {
}

@media all and (max-width: $size-XS) {
}


.newsov {

  article {
    background: #EAF5F5;

    .news-text {
      padding: 38px;
    }
  }

  .button-container {
    display: grid;
    width: 100%;
    margin-top: 50px;

    .linkbtn {
      justify-self: center;
      display: flex;
      flex-wrap: wrap;

      &:hover {
        color: #ffffff;
        background: #2E7474;
      }
    }
  }
}


.pressebericht-start {
  margin-top: 0;

  p.subheading {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 700;
  }

  .presseheadline {
    &::after,
    &::before {
      content: none;
    }
  }

  .post-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media (max-width: 1025px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    @media (max-width: 420px) {
      display: block;
    }

    .post-item {
      grid-column: span 1;
      background: #EAF5F5;

      @media (max-width: 420px) {
        margin-bottom: 16px;
      }

      .post-image {
        height: 335px;
        width: 100%;
        object-fit: cover;

        @media (max-width: 768px) {
          height: auto;
        }
      }

      .entry-content {
        padding: 39px;
        background: #EAF5F5;
        margin-top: -5px;

        h2 {
          line-height: 0;

          a {
            font-size: 2.5rem;
            font-weight: 700;
            line-height: normal;
            color: #000000;
            text-transform: uppercase;

            @media (max-width: 420px) {
              width: 100%;
            }
          }
        }

        p.date {
          font-size: 20px;
          margin-bottom: 0;
        }

        .readmore {
          font-size: 25px;
          font-weight: 700;

          @media (max-width: 1024px) {
            font-size: 2.2rem;
          }

          @media (max-width: 850px) {
            font-size: 1.8rem;
          }

          @media (max-width: 600px) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  .button-container {
    text-align: center;
    margin-top: 48px;
  }

  .kontakt {
    margin-top: 150px;

    .contact-headline {
      text-transform: uppercase;
      font-size: 65px;
      font-weight: 400;
      line-height: 85px;
      margin-bottom: 48px;
    }

    .kontakt-box {
      display: flex;
      column-gap: 60px;

      @media (max-width: 1025px) {
        flex-direction: column;
      }

      .text-links {

        @media (max-width: 1600px) {
          width: 100%;
          margin-bottom: 60px;
        }
      }

      .kontakt-card {
        display: flex;

        @media (max-width: 1025px) {
          width: 100%;
        }

        @media (max-width: 768px) {
          flex-direction: column;
        }

        .imagebox {

          @media (max-width: 1280px) {
            width: 50%;
          }

          @media (max-width: 720px) {
            width: 100%;
          }

          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .textbox {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 50px;

        @media (max-width: 1280px) {
          width: 50%;
        }

        @media (max-width: 720px) {
          width: 100%;
        }

        p {
          line-height: 1.8;
          margin-bottom: 0;

          strong {

            &:first-child {
              color: #FCB21F;
              font-size: 3rem;
              line-height: normal;
              text-transform: uppercase;
            }
          }
        }

        p.flex-start {
          margin-bottom: 0;

          .mailko {
            font-size: 2.0rem;
          }
        }
      }
    }
  }
}
