.preisentwicklung {


  .preisgrafik {
    flex-direction: column;
    background: #EAF5F5;
    padding: 80px 134px;

    @media (max-width: 1025px) {
      padding: 80px 100px;
    }
    @media (max-width: 768px) {
      padding: 80px 40px;
    }

    .diagramm-info {
      margin-top: 40px;

      p {
        font-size: 15px;

        @media (max-width: 1025px) {
          font-size: 12px;
        }

        @media (max-width: 520px) {
          font-size: 10px;
        }
      }
    }
  }
}
