/* Damen Herren Weiche */
.service {
  justify-content: flex-start;
  text-align: left;

  .g-3 {
    margin: 0 0 25px;
  }

  span {
    @include font-size($fontsize-M);
    color: $black;
    line-height: 4rem;
  }

  img {
    margin-right: 10px;
    vertical-align: text-bottom;
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL3) {
  .service {
    .g-3 {
      width: 33.333%;
    }

    span {
      @include font-size($fontsize-MS);
    }
  }
}
@media all and (max-width:$size-XL) {
  .service {
    span {
      @include font-size($fontsize-MS);
    }
  }
}
@media all and (max-width:$size-L2) {
  .service {
    .g-3 {
      width: 50%;
    }
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
  .service {
    span {
      @include font-size($fontsize-S);
      display: inline-block;
      width: 100%;
    }
  }
}
@media all and (max-width:$size-S) {
  .service {
    span {
      display: none;
    }
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
