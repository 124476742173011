/* Tarifbox */
.tarif-box {
  border: none;

  @media (max-width: 736px) {
    margin-bottom: 10%;
  }

  .tarif-name {
    h4 {
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  .tarif-content {

    a.text-link {
      text-decoration: underline;
    }

    @media (max-width: 820px) {
      padding: 30px;
    }

    .tarif-slogan {
      height: 90px;
      margin-bottom: 50px;

      .desktop {
        width: 100%;

        @media (max-width: 1280px) {
          display: none;
        }
        @media (max-width: 736px) {
          display: unset;
        }
        @media (max-width: 350px) {
          display: none;
        }

      }

      .mobile {
        display: none;

        @media (max-width: 1280px) {
          display: unset;
        }
        @media (max-width: 736px) {
          display: none;
        }
        @media (max-width: 350px) {
          display: unset;
        }
      }

      .slogan-text {
        background-size: contain;
        width: 80%;
        min-height: 70px;
        max-height: 80px;
        margin: 0 auto;
        padding: 4%;
        font-size: 2.5rem;
        position: relative;
        top: -80px;
        color: #000000;
        align-items: center;
        display: inline-flex;
        justify-content: center;

        @media (max-width: 1280px) {
          width: 70%;
          padding: 0;
          line-height: 29px;
        }

        @media (max-width: 850px) {
          font-size: 20px;
        }

        @media (max-width: 450px) {
          width: 100%;
        }
      }
    }

    h3 {
      font-size: 85px;

      @media (max-width: 850px) {
        font-size: 60px;
      }

      span {
        font-size: 40px;

        @media (max-width: 850px) {
          font-size: 20px;
        }
      }
    }

    .preisabschlag {
      display: inline-flex;

      p.preis-abschlag {
        font-size: 20px;
        margin-top: -20px;
        margin-bottom: 0;
      }

      svg {
        position: relative;
        top: -13px;
        left: 5px;

        @media (max-width: 850px) {
          top: -18px;
        }

        &:hover {

          .cls-3 {
            fill: #067774;
          }

          #Pfad_1181 {
            fill: #FFF;
          }
        }
      }
    }

    .info-modal {
      display: none;
      position: relative;
      z-index: 1;
      padding: 20px;
      overflow: auto;
      background: #fff;
      max-width: 390px;
      left: 13%;

      @media (max-width: 1280px) {
        left: 50px;
      }

      @media (max-width: 910px) {
        max-width: 300px;
      }

      @media (max-width: 736px) {
        left: 20%;
      }

      @media (max-width: 420px) {
        max-width: 280px;
        left: 12%;
      }
      @media (max-width: 360px) {
        max-width: 250px;
      }


      .modal-content {

        span {
          width: 100%;
          display: flex;
          justify-content: end;
          font-size: 30px;
        }

        p {
          font-size: 15px;
          text-align: left;
        }
      }
    }

    p.anzahl-abschlaege {
      font-size: 16px;
      margin-top: -15px;
    }

    .vorteile-tarifbox {
      justify-content: center;
      display: flex;

      ul {
        list-style: none;
        padding-left: 50px;

        li {
          position: relative;
          margin-bottom: 10px;
          text-align: left;
        }

        li::before {
          content: url("assets/img/check-vorteil-icon-orange.svg");
          position: absolute;
          top: 3px;
          left: -50px;
          display: inline-block;

          @media (max-width: 850px) {
            top: -5px;
          }
        }
      }
    }
  }

  .tarif-buttons {
    .gelb {
      color: #fff;
    }

    a {
      width: 100%;
    }
  }
}

.tarif-popup {
  display:none;
  position: fixed;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  padding: 100px;
  z-index: 1000;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 1730px) {
    top: 57%;
    max-height: 72vh;
  }

  @media (max-width: 520px) {
    padding: 30px;
    width: 90%;
  }

  a.close-link {
    position: absolute;
    top: 50px;
    right: 50px;
    background: #2E7474;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;

    @media (max-width: 520px) {
      top: 30px;
      right: 30px;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    font-size: 40px;

    @media (max-width: 520px) {
      font-size: 32px;
    }
  }

  .popup-headline {
    margin-bottom: 50px !important;

    @media (max-width: 390px) {
      width: 88%;
    }
  }

  h4 {
    font-size: 32px;

    @media (max-width: 520px) {
      font-size: 20px;
    }
  }

  .tarif-disclaimer {

    &:before {
      content: "*";
      position: relative;
      left: -15px;
      top: 20px;
    }

    p {
      font-size: 15px !important;
      line-height: 22px !important;
    }
  }

  table.details,
  table.abschlag {
    width: 100%;

    @media (max-width: 375px) {
      font-size: 14px;
    }

    .table-key {
      font-weight: 700;
    }

    .table-value {
      text-align: right;
    }

    .value-abschlag {
      font-weight: 700;
    }
  }

  .tarif-buttons {
    display: inline-grid;
    row-gap: 30px;
    width: 100%;

    a {
      text-align: center;
    }
  }

  a.text-link {
    text-decoration: underline;
    font-weight: bold;
    font-size: 21px;
    cursor: pointer;
  }

  .weitere-infos {

    p {
      font-size: 1.8rem;
    }
  }
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 1000; /* Das Overlay muss hinter dem Popup liegen */
}

.mehr-zum-tarif {

  @media (max-width: 736px) {
    width: 100%;
  }
}

@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
