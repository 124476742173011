//
// Project Color Definitions
//
$white: #ffffff;
$black: #1c1c1c;
$darkbluegrey: #1A313A;
$bluegrey: #2D3840;
$purple: #8B4577;
$turkis: #4BAAB7;
$lightturkis: #EAF5F5;
$green: #2E7474;
$yellow: #FCB21F;
$lightyellow: #FFC757;
$mittelgrau: #535D64;
$hellgrau: #D1D6D9;

.helltuerkis {
  background: $lightturkis;
}

.hellgrau {
  background: $hellgrau;
}

.weiss {
  color: $white;
}

.bg-weiss {
  background: $white;
}

.gelb {
  background: $yellow;

  .linkbtn,
  h1,
  h2,
  h3,
  h4,
  p,
  table,
  ul {
    color: $white !important;
  }
}
.linkbtn.gelb {
  color: $white;
  border: none;
}

.hellgelb {
  background: $lightyellow;

  .linkbtn,
  h1,
  h2,
  h3,
  h4,
  p,
  table,
  ul {
    color: $white !important;
  }
}

.mittelgrau {
  background: $mittelgrau;

  .linkbtn,
  h1,
  h2,
  h3,
  h4,
  p,
  table,
  ul {
    color: $white !important;
  }
}

.anthrazit {
  background: $darkbluegrey;

  .linkbtn,
  h1,
  h2,
  h3,
  h4,
  p,
  table,
  ul {
    color: $white !important;
  }
}

.gruen {
  background: $green;

  .linkbtn,
  h1,
  h2,
  h3,
  h4,
  p,
  table {
    color: $white !important;
  }
}

.tuerkis {
  background: $turkis;

  .linkbtn,
  h1,
  h2,
  h3,
  h4,
  p,
  table,
  ul {
    color: $white !important;
  }
}

.lila {
  background: $purple;

  .linkbtn,
  h1,
  h2,
  h3,
  h4,
  p,
  table,
  ul {
    color: $white !important;
  }
}
