/* Bildtext */
.bildtext {
  img {
    width: 100%;
  }

  .textbox {
    position: relative;
  }

  .icontext {
    img {
      max-height: 70px;
    }

    h3 {
      margin-bottom: 5px;
    }
  }

  ol {
    padding-left: 0;
    counter-reset: uezw-counter;

    li::before {
      content: '';
      width: 0.5rem;
      height: 110%;
      position: absolute;
      background: #D8D8D8;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }

    li::after {
      content: counter(uezw-counter);
      position: absolute;
      background: $turkis;
      text-align: center;
      left: 0;
      top: 44px;
      border-radius: 50%;
      width: 45px;
      height: 45px;
      z-index: 2;
    }

    li {
      counter-increment: uezw-counter;
      position: relative;
      padding: 40px 0 40px 80px;
    }
  }

  a.linktxt {
    font-weight: bold;
    @include font-size($fontsize-L);
    line-height: 1.8;
  }
}

.bildtext-horizontal-3spalten {
  .wrapper {
    display: flex;
    max-width: 1640px;
    margin: 0 auto;
    @media (max-width: 1680px) {
      flex-direction: column;
    }
    @media (max-width: 1320px) {
      max-width: 1024px;
    }

    .g-6 {
      @media (max-width: 1680px) {
        width: 100%;
        max-width: 1280px;
      }
    }

    .textcontainer {
      display: grid;
      align-content: center;
      @media (max-width: 1680px) {
        margin-bottom: 50px;
      }
    }

    .card-container {
      @media (max-width: 768px) {
        flex-direction: column;
      }

      p {
        .mailko {
          font-size: 1.8rem;
          @media (max-width: 600px) {
            font-size: 1.6rem;
          }
        }
      }
    }

    .container {
      .col.g-12.mb-20 {
        display: flex;
      }

      .imagebox img {
        height: 100%;
      }
    }
  }
}

.markenwerte {
  h4 {
    margin-bottom: 40px;
    position: relative;
  }

  h4::after {
    content: "";
    position: absolute;
    display: inline-block;
    bottom: -25px;
    left: 0;
    width: 85px;
    height: 5px;
    background: $turkis;
  }
}

.page-id-807 .bildtext .textbox.anthrazit {
  align-content: center;
  display: inline-grid;
}

.faktenbox {
  .counter {
    @include font-size($fontsize-FA);
    font-weight: 200;
    display: inline-block;

    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      bottom: 30px;
      left: 0;
      width: 85px;
      height: 5px;
      background: $yellow;
    }
  }
}

.texttable {
  table {
    display: table;
    width: 100%;
    float: left;
    table-layout: fixed;

    a {
      color: $white;
      font-weight: 400;
      text-decoration: underline;
    }
  }

  thead {

    border-bottom: 6px solid #fff;

    tr {
      text-align: left;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      hyphens: auto;
    }
  }

  th {
    font-weight: 600;
    padding: 20px 50px 20px 25px;
  }

  tbody {
    border-bottom: none;
  }

  tr {
    vertical-align: top;
    border-bottom: solid 2px #fff;
  }

  tr:last-of-type {
    border-bottom: none;
  }

  td {
    padding: 20px 50px 20px 25px;
  }
}

.textbox {
  .mobile-table {
    tbody {
      border-bottom: none;

      tr {
        border-bottom: none;

        td {
          width: 100%;
          text-align: left;

          hr {
            height: 2px;
            background-color: #fff;
            border: none;
            margin: 0;
          }
        }
      }
    }

    @media (min-width: 821px) {
      display: none;
    }
    @media (max-width: 820px) {
      display: table;
    }
  }

  .desktop-table {
    @media (min-width: 821px) {
      display: table;
    }
    @media (max-width: 820px) {
      display: none;
    }
  }
}

.wp-block-acf-text100-table {
  td,
  th {
    padding: 20px 50px 20px 0;
    min-width: 350px;
  }

  td {
    padding: 30px 50px 30px 0;
    vertical-align: top;
  }

  th {
    font-weight: bold;
    text-align: left;
  }

  td:last-child,
  th:last-child {
    padding-right: 0;
  }

  tr:not(:last-child) {
    border-bottom: 2px solid #fff;
  }

  tr > td:first-child {
    font-weight: bold;
    vertical-align: top;
  }
}

.iconbox {
  img {
    max-width: 100%;
    min-height: 270px;
  }
}

.page-id-758 {
  .vorteile-auf-einen-blick {
    align-items: center;

    .imagebox {
      img {
        .iconbox {
          img {
            max-width: 100%;
            min-height: unset;
          }
        }

        max-width: 100%;
        min-height: unset;
      }
    }
  }
}

.iconbox-ql {
  @media (max-width: 1680px) {
    padding: 50px 0;
  }

  img {
    max-width: 50%;
  }

  .g-4,
  .g-4:first-child {
    margin-bottom: 30px;
  }

  & + .iconbox-ql {
    padding-top: 0;
    margin-top: -215px;
  }
}

.page-id-1294 {
  .iconbox-ql {
    .g-4,
    .g-4:first-child {
      margin-bottom: 0;
    }

    @media (max-width: 768px) {
      .g-4:nth-of-type(3) {
        margin-top: 50px;
      }
    }
    @media (max-width: 520px) {
      .g-4:nth-of-type(3) {
        margin-top: 0;
      }
    }
  }

  .container-fullwidth.bildtext.textwrap.ptb-150 {
   // padding-top: 0;
  }

  .container-fullwidth.kontaktbox.bildtext.textwrap.ptb-150.mtb-150.tuerkis {
    padding-top: 150px;
    margin-top: 0;
  }
}

.page-id-750 main div:nth-of-type(6) {
  padding-top: 0;
  padding-bottom: 0;
}

.vertxt {
  img {
    min-height: 55vh;
  }
}

.mitarbeiter {
  .imagebox {
    width: 100%;
  }

  p {
    margin-bottom: 0;
  }

  p strong {
    @include font-size($fontsize-L);
  }

  p strong:first-child {
    color: $yellow;
    @include font-size($fontsize-L2);
  }

  .mailko,
  .telko {
    margin-left: 15px;
  }

  .flex-start > .col {
    margin-right: 2%;
  }

  img {
    height: 450px;
    width: 100%;
    float: left;
  }

  @media (max-width: 520px) {
    .flex-start > .col,
    .flex > .col {
      width: 100% !important;
    }
    .imagebox img {
      height: 300px !important;
    }
  }
}

.ctabs {
  position: relative;

  .tablabels {
    ul {
      list-style: none;

      li {
        float: left;
        cursor: pointer;
        position: relative;
        margin-right: 10%;
      }

      li.current::after {
        content: "";
        position: absolute;
        display: inline-block;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 5px;
        background: $yellow;
      }
    }
  }

  .panels {
    float: left;
    clear: both;
    position: relative;
    width: 100%;
    min-height: 600px;
  }

  .panel {
    width: 100%;
    display: none;
    box-sizing: border-box;
  }
}

.panel.current {
  display: flex;
}

.wp-block-acf-iconbox-3spalten-ql .g-4:hover,
.wp-block-acf-stromtarife-3spalten .g-4:hover {
  background: $hellgrau;
}

.wp-block-acf-stromtarife-3spalten .g-4,
.wp-block-acf-stromtarife-3spalten .g-4:first-child {
  margin-bottom: 2%;
}

.wp-block-acf-stromtarife-3spalten h4 {
  text-transform: uppercase;
}

.wp-block-acf-stromtarife-3spalten .g-4 img {
  height: 140px;
  max-width: 80%;
}

.wp-block-acf-text50link-img50 {
  .imagebox {
    img {
      width: calc(100% + 18vw);
      max-width: none;
    }
  }

  .imagebox.imgleft {
    img {
      margin-left: -18vw;
    }
  }
}

.page-id-3650,
.page-id-823 {
  .bildtext img {
    width: auto;
  }
}

@media all and (max-width: $size-XXL) {
}

@media all and (max-width: $size-XL2) {
}

@media all and (max-width: $size-XL) {
  .bildtext {
    .vertxt {
      .image-wrapper {
        width: 100%;
        float: left;
      }

      figcaption {
        display: none;
      }
    }
  }

  .text-align-right + .imagebox {
    margin-left: auto;
  }

  .textbox {
    order: 2;
  }

  .mitarbeiter img {
    height: 300px;
  }

  .wp-block-acf-text50link-img50 .imagebox img {
    width: 100%;
    max-width: 100%;
  }

  .wp-block-acf-text50link-img50 .textbox {
    order: inherit;
  }

  .kontaktbox {
    .g-5 {
      width: 23.584%;
    }

    .g-7 {
      width: 74.528%;
    }
  }
}

@media all and (max-width: $size-L) {
}

@media all and (max-width: $size-M2) {
  .wp-block-acf-text60table-img40 {
    .g-40,
    .g-60 {
      width: 100%;
    }
  }

  .wp-block-acf-iconbox-4spalten {
    .g-3 {
      width: 49.056%;
      margin-bottom: 1.56vw;
    }

    .g-3:first-child {
      margin-bottom: 1.56vw;
    }
  }

  .wp-block-acf-icon-text-grid {
    .g-3 {
      position: relative;
    }

    .imagebox {
      position: absolute;
      width: 70%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        max-width: 100%;
      }
    }
  }

  .faktenbox .counter {
    @include font-size($fontsize-XXL);
    line-height: 10.5rem;
    margin-bottom: 20px;

    &::after {
      bottom: 15px;
    }
  }

  .bildtext ol li::after {
    line-height: 2.5;
  }

  .mitarbeiter img {
    height: 250px;
  }

  .iconbox {
    img {
      max-width: 90%;
    }
  }

  .ctabs {
    .panels {
      min-height: 400px;
    }
  }
}

.kundenzufriedenheit-siegel.ptb-150 {
  padding: 150px 0 !important;
}

@media all and (max-width: $size-M) {
}

@media all and (max-width: $size-S2) {
}

@media all and (max-width: $size-S) {
  .bildtext {
    .imagebox {
      order: 1;
    }

    .textbox {
      order: 2;
    }
  }

  .faktenbox .counter {
    font-size: 6.5rem;
  }

  .mitarbeiter img {
    height: 200px;
  }

  .wp-block-acf-icon-text-grid {
    .imagebox {
      position: relative;
      width: 100%;
      left: inherit;
      top: inherit;
      transform: none;

      img {
        max-width: 70%;
      }
    }
  }

  .page-id-809 main .col.g-40.textbox.anthrazit div:last-of-type {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .page-id-809 main div:nth-of-type(5) {
    padding-top: 50px;
  }

  .page-id-5695 main div:nth-of-type(6),
  .page-id-753 main div:nth-of-type(2) {
    padding-top: 50px;
  }

  .page-id-758 main div:nth-of-type(3) {
    padding: 50px 0;
  }

  .page-id-776 main div:nth-of-type(2) {
    padding-bottom: 50px;
  }

  .page-id-772 .bildtext .container .helltuerkis {
    padding-bottom: 50px;
    padding-top: 50px;
  }
  .page-id-772 .bildtext .container .helltuerkis:nth-of-type(2) {
    padding-bottom: 50px;
    padding-top: 0;
  }

  .page-id-763 .bildtext .container .helltuerkis {
    padding-top: 25px;
  }

  .page-id-760 .container-fullwidth.bildtext.textwrap.ptb-150.tuerkis.mtb-150,
  .page-id-758 .container-fullwidth.bildtext.textwrap.ptb-150.tuerkis.mtb-150 {
    margin: 0 0 50px;
  }

  .page-id-772 main div:nth-of-type(6) {
    padding-top: 50px;
  }

  .page-id-811 .container-fullwidth.bildtext.wbg.ptb-250 .helltuerkis,
  .page-id-797 .container-fullwidth.bildtext.wbg.ptb-250 .helltuerkis,
  .page-id-793 .container-fullwidth.bildtext.wbg.ptb-250 .helltuerkis,
  .page-id-795 .container-fullwidth.bildtext.wbg.ptb-250 .helltuerkis,
  .page-id-794 .container-fullwidth.bildtext.wbg.ptb-250 .helltuerkis,
  .page-id-792 .container-fullwidth.bildtext.wbg.ptb-250 .helltuerkis,
  .page-id-779 .container-fullwidth.bildtext.wbg.ptb-250 .helltuerkis,
  .page-id-774 .container-fullwidth.bildtext.wbg.ptb-250 .helltuerkis,
  .page-id-775 .container-fullwidth.bildtext.wbg.ptb-250 .helltuerkis {
    width: 100%;
    margin-bottom: 50px !important;
  }

  .page-id-773 .container-fullwidth.bildtext.textwrap.ptb-150.tuerkis.mtb-150,
  .page-id-776 .container-fullwidth.kontaktbox.bildtext.textwrap.ptb-150.mtb-150.tuerkis,
  .page-id-750 .container-fullwidth.bildtext.textwrap.ptb-150.tuerkis.mtb-150 {
    margin: 0;
  }

  .page-id-740 .container-fullwidth.bildtext.textwrap.ptb-150.tuerkis.mtb-150 {
    margin-top: 50px;
    margin-bottom: 0;
  }

  .page-id-765 .container-fullwidth.bildtext.iconbox.wbg.ptb-250,
  .page-id-742 .container-fullwidth.markenwerte.bildtext.textwrap.ptb-150.pb-0 {
    padding-top: 0;
  }

  .page-id-738 .container-fullwidth.bildtext.textwrap.ptb-150 {
    padding-top: 0;
  }

  .page-id-738 .container-fullwidth.bildtext.textwrap.ptb-150.gelb.mtb-150 {
    padding-top: 50px;
    margin: 0;
  }

  .page-id-738 .container-fullwidth.bildtext.textwrap.ptb-150.tuerkis.mtb-150 {
    padding-top: 25px;
    margin-bottom: 25px;
  }

  .page-id-738 .container-fullwidth.markenwerte.bildtext.textwrap.ptb-150 {
    padding-top: 0;

    .g-12.pd-50.mb-50 {
      margin-bottom: 0;
    }
  }

  .page-id-730 .container-fullwidth.bildtext.textwrap.ptb-150.gelb.mtb-150 {
    margin-bottom: 0;
  }

  .page-id-731 .container-fullwidth.bildtext.textwrap.ptb-150.hgmode {
    min-height: unset;
  }

  .wp-block-acf-bildtext-vertikal-3spalten .g-4 {
    width: 100%;
  }
  .page-id-8 .container-fullwidth.bildtext.karriereql.wbg.ptb-250 .col.g-6.imagebox {
    padding-top: 50px;
  }
  .container-fullwidth.bildtext.textwrap.ptb-150.helltuerkis .g-9 {
    padding-top: 50px;
  }
  .page-id-8 .container-fullwidth.bildtext.textwrap.ptb-150.pb-0 {
    padding-top: 0;
  }
  .page-id-8 .container-fullwidth.newsov.bildtext.wbg article {
    padding-bottom: 25px;
  }
  .container-fullwidth.bildtext.textwrap.ptb-150.kundenzufriedenheit-siegel .g-9 {
    padding-top: 50px;
  }
}

@media all and (max-width: $size-XS2) {
}

@media all and (max-width: $size-XS) {
}


.kontaktbox {

  @media (max-width: 670px) {
    margin-bottom: 0;
    margin-top: 50px;
  }

  .container.flex {

    @media (max-width: 670px) {
      flex-flow: column wrap;
    }

    .g-7.pr-1.pl-1 {

      @media (max-width: 670px) {
        width: 100%;
      }
    }

    p.kontakt {

      @media (max-width: 733px) {
        font-size: 2.5rem;
      }

      a {

        @media (max-width: 1280px) {
          font-size: 3.5rem;
        }
        @media (max-width: 1035px) {
          font-size: 3.3rem;
        }
        @media (max-width: 830px) {
          font-size: 3.0rem;
        }
        @media (max-width: 772px) {
          font-size: 2.8rem;
        }
        @media (max-width: 733px) {
          font-size: 2.5rem;
        }
      }
    }
  }
}

.kontaktbox .g-7 div:nth-of-type(2) p.kontakt {

  @media (max-width: 490px) {
    font-size: 2.5rem;
    max-width: 70%;
  }
}

.hgmode.without-background {
  padding: 210px 0 25px 0;
  min-height: unset;
}

@media all and (max-width: $size-XXL) {
  .hgmode.without-background {
    padding: 210px 0 100px 0;
  }
}

@media all and (max-width: $size-XL) {
  .hgmode.without-background {
    padding: 150px 0 50px 0;
  }
}

@media all and (max-width: $size-S) {
  .hgmode.without-background {
    padding: 50px 0 50px 0;
  }
}
