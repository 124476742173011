/* Video */
.videobox + .textwrap {
  padding-top: 0;
}

.video-modul {
  .g-7 h3 {
    margin-top: -0.35em;
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {
  .video-modul {
    .g-5,
    .g-7 {
      width: 50%;
    }

    .g-7 {
      padding-right: 0;

      h3 br {
        display: none;
      }
    }
  }
}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  .video-modul {
    .g-5,
    .g-7 {
      width: 100%;
    }

    .g-7 {
      padding: 25px 0 0;
    }
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
