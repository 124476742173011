//
// Spacings
//

.pd-25 {
	padding: 25px;
}

.pd-25-pdl-40 {
	padding: 25px 40px;
}

.pd-40 {
	padding: 40px;
}

.pd-50 {
	padding: 50px;
}

.pd-60 {
	padding: 60px;
}

.pd-100 {
	padding: 100px;
}

.pt-0 {
	padding-top: 0 !important;
}

.pt-60 {
	padding-top: 60px;
}

.pt-100 {
	padding-top: 100px;
}

.ptb-10 {
	padding: 10px 0;
}

.ptb-20 {
	padding: 20px 0;
}

.ptb-25 {
	padding: 25px 0;
}

.ptb-30 {
	padding: 30px 0;
}

.ptb-50 {
	padding: 50px 0;
}

.ptb-80 {
	padding: 80px 0;
}

.ptb-100 {
	padding: 100px 0;
}

.ptb-150 {
	padding: 150px 0;
}

.ptb-200 {
	padding: 200px 0;
}

.pt50-pb100 {
	padding: 50px 0 100px;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pb-20 {
	padding-bottom: 20px;
}

.pb-25 {
	padding-bottom: 25px;
}

.pb-50 {
	padding-bottom: 50px;
}

.pb-80 {
	padding-bottom: 80px;
}

.pb-100 {
	padding-bottom: 100px;
}

.pb-150 {
	padding-bottom: 150px;
}

.ptb-60 {
	padding: 60px 0;
}

.ptb-100 {
	padding: 100px 0;
}

.ptb-250 {
	padding: 250px 0;
}

.pl-30 {
	padding-left: 30px;
}

.pl-60 {
	padding-left: 60px;
}

.pr-30 {
	padding-right: 30px;
}

.plr-50-ptb-100 {
	padding: 100px 50px;
}
/* Margin Directions */
.mt-10 {
	margin-top: 10px;
}

.mt-25 {
	margin-top: 25px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-50 {
	margin-top: 50px;
}

.mtm-50 {
	margin-top: -50px;
}

.mt-60 {
	margin-top: 60px;
}

.mt-80 {
	margin-top: 80px;
}

.mt-100 {
	margin-top: 100px;
}

.mr-10 {
	margin-right: 10px;
}

.mb-10 {
	margin-bottom: 0.5%;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-50 {
	margin-bottom: 50px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-100 {
	margin-bottom: 150px;
}

.mb-150 {
	margin-bottom: 150px;
}

.mr-10 {
	margin-right: 10px;
}

.mr-20 {
	margin-right: 20px;
}

.mr-40 {
	margin-right: 40px;
}

.mtb-150 {
	margin: 150px 0;
}

.wbg + .wbg .imagebox.ptb-150,
.wbg.ptb-150 + .wbg .imagebox.ptb-150,
.wbg.ptb-150 + .wbg.ptb-150 {
	padding-top: 0;
}

.wbg.ptb-150 + .kontaktform {
	margin-top: -75px;
}

.absx,
.absxy,
.absxy .absxy,
.absy {
	position: absolute;
}

.absxy {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.absx {
	left: 50%;
	transform: translateX(-50%);
}

.absy {
	top: 50%;
	transform: translateY(-50%);
}

#cl-block_614d784f4b5fa,
#cl-block_6150df1f8353a {
	padding-top: 0;
	margin-top: -50px;
}

.page-id-8 main div:nth-of-type(6) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-8 main div:nth-of-type(8) {
	padding-top: 0;
}
.page-id-718 main div:nth-of-type(7) {
	margin-top: 0;
}
.page-id-719 main div:nth-of-type(5) {
	padding-top: 0;
	margin-bottom: -100px;
}
.page-id-719 main div:nth-of-type(8) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-720 main div:nth-of-type(5) {
	padding-top: 0;
	padding-bottom: 0;
	margin-bottom: -100px;
}
.page-id-720 main div:last-of-type {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-721 main div:nth-of-type(5) {
	padding: 0;
	margin-bottom: -100px;
}
.page-id-721 main div:nth-of-type(7) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-722 main div:nth-of-type(5) {
	padding-top: 0;
}
.page-id-722 main div:nth-of-type(6),
.page-id-722 main div:nth-of-type(8) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-730 main div:nth-of-type(2),
.page-id-730 main div:nth-of-type(6){
	margin-top: 0;
}
.page-id-730 main div:nth-of-type(4) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-730 main div:last-of-type {
	padding-bottom: 0;
}
.page-id-729 main div:nth-of-type(5) {
	padding-bottom: 0;
}
.page-id-731 main div:nth-of-type(3) {
	padding-bottom: 0;
}
.page-id-732 main div:nth-of-type(5) {
	padding-bottom: 0;
}
.page-id-732 main div:nth-of-type(6) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-738 main div:nth-of-type(2) {
	padding-bottom: 0;
}
.page-id-738 main div:nth-of-type(5),
.page-id-738 main div:nth-of-type(9) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-738 main div:nth-of-type(7) {
	margin-top: 0;
}
.page-id-742 main div:nth-of-type(5) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-742 main div:last-of-type {
	margin-top: 0;
	margin-bottom: 0;
}
.page-id-740 main div:nth-of-type(3) {
	padding-top: 0;
}
.page-id-740 main div:last-of-type {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-741 main div:nth-of-type(2) {
	margin-bottom: -50px;
}
.page-id-741 main div:nth-of-type(5) {
	padding-bottom: 0;
}
.page-id-750 .container-fullwidth.bildtext.iconbox.wbg.ptb-250 {
	padding-bottom: 0;
}
.page-id-753 main div:nth-of-type(2) {
	padding-bottom: 0;
}
.page-id-753 main div:nth-of-type(6) {
	padding-bottom: 0;
}
.page-id-5695 main div:nth-of-type(3) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-5695 main div:nth-of-type(5) {
	margin-top: 0;
	margin-bottom: 0;
}
.page-id-5695 main div:nth-of-type(6) {
	padding-top: 150px;
}
.page-id-758 main div:nth-of-type(5){
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-760 main div:nth-of-type(3) {
	margin-top: 0;
}
.page-id-760 main div:nth-of-type(4),
.page-id-760 main div:nth-of-type(5),
.page-id-763 main div:last-of-type {
	padding-top: 0;
}
.page-id-763 main div:nth-of-type(3) {
	margin-top: 0;
}
.page-id-762 main div:nth-of-type(3),
.page-id-762 main div:nth-of-type(5) {
	padding-top: 0;
}
.page-id-761 main div:nth-of-type(2),
.page-id-761 main div:last-of-type {
	padding-bottom: 0;
}
.page-id-765 main div:nth-of-type(4){
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-5984 main div:nth-of-type(3),
.page-id-5984 main div:nth-of-type(5) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-772 main div:nth-of-type(5) {
	margin-top: 0;
}
.page-id-772 main div:nth-of-type(6) {
	padding-top: 150px;
}
.page-id-772 main div:nth-of-type(6) div:last-of-type {
	padding-top: 0;
}
.page-id-776 main div:nth-of-type(2) {
	padding-bottom: 0;
}
.page-id-773 main div:nth-of-type(2),
.page-id-773 main div:last-of-type {
	padding-bottom: 0;
}
.page-id-773 main div:nth-of-type(4) {
	margin-top: 0;
}
.page-id-775 main div:nth-of-type(2),
.page-id-775 main div:nth-of-type(4) {
	padding-bottom: 0;
}
.page-id-779 main div:nth-of-type(3) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-5366 main div:nth-of-type(3) {
	padding-top: 0;
}
.page-id-5366 main div:last-of-type,
.page-id-781 main div:last-of-type {
	margin-bottom: 0;
}
.page-id-792 main div:nth-of-type(3) {
	padding-bottom: 0;
}
.page-id-794 main div:nth-of-type(5) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-795 main div:nth-of-type(2),
.page-id-793 main div:nth-of-type(2) {
	min-height: unset;
}
.page-id-793 main div:nth-of-type(7),
.page-id-797 main div:nth-of-type(7),
.page-id-796 main div:nth-of-type(3),
.page-id-796 main div:last-of-type {
	padding-bottom: 0;
}
.page-id-797 main div:nth-of-type(3),
.page-id-797 main div:nth-of-type(6),
.page-id-796 main div:nth-of-type(6) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-804 main div:nth-of-type(3) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-805 main div:nth-of-type(6) {
	padding-bottom: 125px !important;
	@media (max-width: 520px) {
		padding-bottom: 50px !important;
	}
}
.page-id-805 main div:nth-of-type(7) div:last-of-type div:nth-of-type(6) {
	padding-bottom: 0 !important;
}
.page-id-808 main div:nth-of-type(3),
.page-id-808 main div:nth-of-type(5) {
	padding-top: 0;
	padding-bottom: 0;
}
.page-id-5458 main div:nth-of-type(2) {
	padding-bottom: 0;
}
.page-id-5458 main div:nth-of-type(5),
.page-id-5458 main div:nth-of-type(7) {
	padding-top: 0;
	padding-bottom: 0;
}
#tarifwrap {
	padding-bottom: 0;
}
@media all and (min-width:$size-XXL) {
	.ptb-250 {
		padding: 125px 0;
	}

	#tarifwrap {
		padding: 250px 0;
		padding-bottom: 0;
	}

	header + .ptb-250 {
		padding: 250px 0 125px;
	}


}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
	.absx,
	.absxy,
	.absy {
		position: relative;
	}

	.ptb-250 {
		padding: 150px 0;
	}

	.ptb-150 {
		padding: 90px 0;
	}

	.pd-50 {
		padding: 35px;
	}

	.pd-100 {
		padding: 60px;
	}

	.wp-block-acf-iconbox-4spalten .pd-50 {
		padding: 25px;
	}
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
	.ptb-50 {
		padding: 30px 0;
	}

	.pd-50 {
		padding: 25px;
	}

	.ptb-150 {
		padding: 100px 0;
	}

	.ptb-100 {
		padding: 75px 0;
	}

	.pt-100 {
		padding-top: 75px;
	}
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
	.ptb-150 {
		padding: 75px 0;
	}

	.ptb-100 {
		padding: 50px 0;
	}

	.pt-100 {
		padding-top: 50px;
	}
}

@media all and (max-width:$size-S) {
	#cl-block_614d784f4b5fa {
		padding-top: 0;
		margin-top: 0;
	}

	.ptb-150 {
		padding: 50px 0;
	}

	.ptb-60 {
		padding: 25px 0;
	}

	.ptb-250 {
		padding: 50px 0;
	}

	.plr-50-ptb-100 {
		padding: 40px 20px;
	}

	.pt50-pb100 {
		padding: 40px 0;
	}

	.wbg.ptb-150 + .kontaktform {
		margin-top: 0;
	}

	.pd-40 {
		padding: 30px;
	}

	.pd-100 {
		padding: 40px;
	}

	.pb-100 {
		padding-bottom: 40px;
	}

	.pb-150 {
		padding-bottom: 60px;
	}

	.ptb-100 {
		padding: 40px 0;
	}

	.pt-100 {
		padding-top: 40px;
	}
	.mtb-150 {
		margin: 50px 0;
	}
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
