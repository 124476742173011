//
// Main CSS
//

::selection {
  color: $white;
  background: $green;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: $green;
}

::-webkit-scrollbar-track {
  background: $bluegrey;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $green;
}

main {
  margin-top: 141px;
   @media (max-width: 1024px) {
     margin-top: 120px;
   }
  @media (max-width: 480px) {
    margin-top: 100px;
  }
}

main > div.ptb-250:last-child {
  padding-bottom: 0;
}

@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
