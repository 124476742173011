/* Text Module */
.accordion {
  .frage {
    position: relative;
    cursor: pointer;
    margin-top: 20px;
    border: 1px solid $mittelgrau;

    &::after {
      content: url("assets/img/accordion-toggle-icon.svg");
      display: inline-block;
      position: absolute;
      right: 25px;
      top: 35px;
      transition: all 0.5s ease-in-out;
    }

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }

    .faq-ov {
      height: 100%;
      width: 25px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    .faq-hl {
      position: relative;
      z-index: 10;
      font-weight: bold;
      @include font-size($fontsize-L);
      line-height: 1.8;
      hyphens: auto;
    }
  }

  .frage.active {
    .faq-ov {
      width: 100%;

      & + span {
        z-index: 300;
      }
    }

    .faq-ov.anthrazit + .faq-hl {
      color: #fff;
    }

    &::after {
      transform-origin: 50% center;
      transform: rotate(180deg);
    }
  }

  .antwort {
    display: none;
    border: 1px solid $mittelgrau;
    border-top: 0;
  }

  .antwort p,
  .frage span {
    padding: 0;
    transition: 0.8s;
    transition-timing-function: ease-out;
  }

  .accordion-content ul {
    list-style: square;
  }

  .accordion-content.anthrazit ul li {
    color: #fff;
  }
}

.videopop {
  .frage {
    position: relative;
    cursor: pointer;
    margin-top: 20px;
    border: 1px solid $mittelgrau;

    &::after {
      content: url("assets/img/accordion-toggle-icon.svg");
      display: inline-block;
      position: absolute;
      right: 25px;
      top: 35px;
      transition: all 0.5s ease-in-out;
      transform: rotate(-90deg);
    }

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }

    .vpop-ov {
      height: 100%;
      width: 25px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    .vpop-hl {
      position: relative;
      z-index: 10;
      font-weight: bold;
      @include font-size($fontsize-L);
      line-height: 1.8;
      hyphens: auto;
      color: #1c1c1c;
    }
  }

  .vlbox {
    display: none;
  }
}

.downloads {
  a {
    width: 100%;
    display: inline-block;
    float: left;
    position: relative;
    cursor: pointer;
    margin-top: 20px;
    border: 1px solid $mittelgrau;
    color: inherit;

    &::after {
      content: url("assets/img/download-icon.svg");
      display: inline-block;
      position: absolute;
      right: 25px;
      top: 25px;
      transition: all 0.5s ease-in-out;
    }

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }

    .dl-ov {
      height: 100%;
      width: 25px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    .dl-ov.anthrazit + .dl-name {
      color: #fff;
    }

    .dl-name {
      position: relative;
      z-index: 10;
      font-weight: bold;
    }

    span {
      transition: 0.8s;
      transition-timing-function: ease-out;
    }
  }

  a:hover {
    .dl-ov {
      width: 100%;

      & + span {
        z-index: 300;
      }
    }
  }
}

.mittelgrau .downloads {
  a {
    border: 1px solid #fff;
  }
}

.karriereql {
  .job a {
    width: 100%;
    display: inline-block;
    float: left;
    position: relative;
    cursor: pointer;
    margin-top: 20px;
    border: 1px solid $mittelgrau;
    color: inherit;
    text-align: left;

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }

    .dl-ov {
      height: 100%;
      width: 25px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    .dl-name {
      display: inline-block;
      position: relative;
      z-index: 10;
      font-weight: bold;
      width: 93%;
    }

    span {
      transition: 0.8s;
      transition-timing-function: ease-out;
    }
  }

  a:hover {
    .dl-ov {
      width: 100%;

      & + span {
        z-index: 300;
      }
    }
  }
}

.karriereql-neu {
  background: #EAF5F5;
  padding: 125px 0 !important;
  .flex {
    flex-flow: column;
    text-align: center;
    @media (max-width: 1024px) {
      flex-flow: column-reverse;
    }
  }
  .textbox {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    .g-9 {
      margin: 0 auto;
    }
  }
  .imagebox {
    margin: 0 auto;
    text-align: center;
    @media (max-width: 1180px) {
      width: 75%;
    }
    @media (max-width: 520px) {
      width: 95%;
    }
  }
  .job a {
    width: 100%;
    display: inline-block;
    float: left;
    position: relative;
    cursor: pointer;
    margin-top: 20px;
    border: 1px solid $mittelgrau;
    color: inherit;
    text-align: left;

    &::after {
      content: "";
      background: url(./assets/img/Iconionic-ios-arrow-round-forward.svg);
      display: inline-block;
      float: right;
      width: 22px;
      height: 14px;
      position: absolute;
      top: 40%;
      right: 20px;
    }

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
    }

    .dl-ov {
      height: 100%;
      width: 25px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    .dl-name {
      display: inline-block;
      position: relative;
      z-index: 10;
      font-weight: bold;
      width: 93%;
    }

    span {
      transition: 0.8s;
      transition-timing-function: ease-out;
    }
  }

  a:hover {
    .dl-ov {
      width: 100%;

      & + span {
        z-index: 300;
      }
    }
  }
}

/* Anpassung für Karriereseite */
.page-id-7498,
.page-id-7924 {
  .textwrap.ptb-150 {
    &:nth-of-type(2) {
      padding: 125px 0;
    }
  }
  .textwrap.ptb-150 {
    &:nth-of-type(3) {
      padding: 0 0 0 0;
    }
  }
}


.newsov {
  h5 {
    text-transform: uppercase;
    font-weight: bold;
  }

  a:hover {
    background: none;
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
