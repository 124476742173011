/* Sondermodule */
.pum-container .pum-content p {
  color: #fff;
}

.pum-container .pum-content {
  outline: 0;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  .wp-block-acf-kunden-zufriedenheit .pr-2 {
    padding-right: 0;
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  .wp-block-acf-kunden-zufriedenheit {
    .g-3 {
      text-align: center;
    }

    img {
      max-width: 60%;
      margin-bottom: 25px;
    }
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
