.post-type-archive-uezwpresse {

  .pressecontainer {
    margin-top: 200px;
    margin-bottom: 200px;

    @media (max-width: 1025px) {
      margin-top: 150px;
    }

    h1 {

      &:before,
      &:after {
        content: none;
      }
    }

    .taxonomy-filter {
      margin-top: 18px;
      margin-bottom: 48px;

      .filter-buttons {
        width: 100%;
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr 1fr 1fr;

        @media (max-width: 1025px) {
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 540px) {
          grid-template-columns: 1fr;
        }

        a.filter-button {
          position: relative;
          border: 1px solid #B1B1B1;
          padding: 25px 60px 25px 25px;
          font-size: 25px;
          color: #000000;

          @media (max-width: 680px) {
            font-size: 18px;
            padding: 15px;
          }

          &.active {
            background: #EAF5F5;
          }
        }
      }
    }

    .post-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 16px;

      @media (max-width: 1025px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 680px) {
        grid-template-columns: 1fr;
      }

      @media (max-width: 420px) {
        display: block;
      }

      .post-item {
        background: #EAF5F5;

        @media (max-width: 420px) {
          margin-bottom: 16px;
        }

        .post-image {
          height: 335px;
          width: 100%;
          object-fit: cover;

          @media (max-width: 680px) {
            height: auto;
          }
        }

        .entry-content {
          padding: 38px;

          .date {
            font-size: 20px;
            margin-bottom: 0;
          }

          h2.title {
            line-height: 0;
            margin-bottom: 14px;

            a {
              font-size: 2.5rem;
              font-weight: 700;
              line-height: normal;
              color: #000000;
              text-transform: uppercase;

              @media (max-width: 420px) {
                width: 100%;
              }
            }
          }

          .teaser {

          }

          .readmore {
            font-size: 25px;
            font-weight: 700;

            @media (max-width: 1024px) {
              font-size: 2.2rem;
            }

            @media (max-width: 850px) {
              font-size: 1.8rem;
            }

            @media (max-width: 600px) {
              font-size: 1.6rem;
            }
          }
        }
      }
    }

    .button-container {
      text-align: center;
      margin-top: 48px;

      .readmore-presseartikel {

        &:hover {
          cursor: pointer;
        }
      }
    }

    .kontakt {
      margin-top: 150px;

      @media (max-width: 850px) {
        margin-top: 100px;
      }

      .contact-headline {
        text-transform: uppercase;
        font-size: 65px;
        font-weight: 400;
        line-height: 85px;
        margin-bottom: 48px;
      }

      .kontakt-box {
        display: flex;
        column-gap: 60px;

        @media (max-width: 1680px) {
          flex-direction: column;
        }

        .text-links {

          @media (max-width: 1680px) {
            width: 100%;
            margin-bottom: 60px;
          }
        }

        .kontakt-card {
          display: flex;
          max-height: 50vh;

          @media (max-width: 1680px) {
            width: 100%;
          }

          @media (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 150px;
          }

          .imagebox {

            @media (max-width: 1680px) {
              width: 50%;
            }

            @media (max-width: 768px) {
              margin-bottom: -5px;
              width: 100%;
            }

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              object-position: top;

              @media (max-width: 768px) {
                max-height: 50vh;
                width: 100%;
              }
            }
          }
        }

        .textbox {
          margin-top: 0;
          margin-bottom: 0;
          padding-bottom: 50px;

          @media (max-width: 1680px) {
            width: 50%;
          }

          @media (max-width: 768px) {
            width: 100%;
          }

          p {
            line-height: 1.8;
            margin-bottom: 0;

            strong {

              &:first-child {
                color: #FCB21F;
                font-size: 3rem;
                line-height: normal;
                text-transform: uppercase;
              }
            }
          }

          p.flex-start {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
