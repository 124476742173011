/* Tarifrechner */
#tarifrechner {
  .hidden {
    display: none;
  }

  input[type="text"],
  select,
  textarea {
    padding: 10px 20px;
    box-sizing: border-box;
    width: 100%;
    margin-right: 16px;
    border: 2px solid $white;
    margin-bottom: 16px;
    outline: none;
  }

  input[type="text"] {
    background: none;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    appearance: none;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    display: inline-block;
    @include font-size($fontsize-L);
    line-height: 1.8;
    min-width: max-content;
    hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;

    @media (max-width: 400px) {
      display: inline;
    }
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 13px;
    width: 18px;
    height: 18px;
    border: 2px solid $darkbluegrey;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 13px;
    left: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    background: #fff;
    border: 4px solid $green;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  .form-group {

    .sub {

      .flex-center {

        @media (max-width: 1280px) {
          justify-content: left;
        }
      }
    }

    .g-4 {

      @media (max-width: 1280px) {
        width: 49% !important;
      }
      @media (max-width: 925px) {
        width: 100% !important;
      }

      br {
        @media (max-width: 925px) {
          display: none;
        }

        @media (max-width: 600px) {
          display: inline;
        }

        @media (max-width: 400px) {
          display: none;
        }
      }

      &:first-of-type {
        text-align: right;
        padding-right: 40px;

        @media (max-width: 925px) {
          text-align: left;
          padding-right: 0;
        }
      }
    }

    button {

      @media (max-width: 430px) {
        width: 100%;
        padding: 23px 20px;
      }
    }
  }

  .tarifart {
    padding: 0 86px;

    @media (max-width: 1280px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 40px;
    }

    @media (max-width: 1025px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 700px) {
      grid-template-columns: 1fr;
      text-align: left;
      min-width: unset;
    }

    @media (max-width: 480px) {
      padding: 0 20px;
    }

    [type=radio]:not(:checked) + label {
      @media (max-width: 340px) {
        text-align: left;
        min-width: unset;
      }
    }

    .g-2 {

      @media (max-width: 1280px) {
        width: unset;
      }
    }
  }

  .smartmeter-modal {

    p {
      margin-bottom: 0;
    }

    svg {
      position: relative;
      top: -30px;
      left: 100px;

      @media (max-width: 850px) {
        top: -27px;
        left: 78px;
        width: 20px;
        height: 20px;
      }

      @media (max-width: 600px) {
        left: 65px;
      }
    }
  }

  .smart-modal {
    display: none;
    position: relative;
    z-index: 1;
    padding: 20px;
    overflow: auto;
    background: #fff;
    max-width: 390px;
    justify-self: center;

    span {
      width: 100%;
      display: flex;
      justify-content: end;
      font-size: 30px;
    }

    p {
      font-size: 15px;
      text-align: left;
    }
  }

  .form-zaehler-haushalt {
    padding: 32px 48px 50px 48px;

    @media (max-width: 370px) {
      padding: 32px 20px 50px 20px;
    }

    h5 {
      font-size: 40px;
      font-weight: 700;
    }

    input {
      background: $white;
      margin: 8px 0 0 0 !important;
      font-size: 2.5rem;
    }

    label {
      color: $white;
    }

    .w50-16:first-of-type {

      @media (max-width: 625px) {
        margin-bottom: 20px;
      }
    }
  }

  .auswahl {
    padding: 80px 86px 139px 86px;

    @media (max-width: 740px) {
      padding: 80px 20px 139px 20px;
    }

    .bg-weiss {
      padding: 25px 50px;

      @media (max-width: 450px) {
        padding: 25px 10px;
      }
    }

    .smartmeter-modal {
      height: 33px;

      .smartmeter-abschlag {
        line-height: 33px;
      }
    }

    .form-ht-nt {

      .g-6 {

        @media (max-width: 1275px) {
          width: 100%;
        }
      }

      .g-6:first-of-type {

        @media (max-width: 1275px) {
          margin-bottom: 20px;
        }
      }

      input {
        margin: 0;
        font-size: 2.5rem;

        @media (max-width: 1680px) {
          font-size: 1.6rem;
        }
      }
    }
  }
}

.preisbremse {
  background: url("assets/img/pinselstrich.svg") no-repeat;
  background-size: contain;
  width: 78%;
  min-height: 70px;
  display: inline-block;
  margin: 0 auto;
  padding: 4%;
  color: #fff;
  font-size: 2.2rem;
}

.tarifform {
  .g-33 {
    height: 150px;
    position: relative;
  }

  .g-33:first-child input[type="text"] {
    border-right: 0;
  }

  input[type="text"] {
    @include font-size($fontsize-L);
    padding: 10px 20px 10px 110px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    outline: none;
    border: 2px solid $hellgrau;
    position: relative;
  }

  .g-33:first-child label::before {
    content: url("assets/img/plz-marker-gelb-tarifrechner.svg");
    height: 45px;
    width: auto;
    display: inline-block;
    position: absolute;
    left: 50px;
    z-index: 20;
    top: 50%;
    transform: translateY(-50%);
  }

  .g-33:nth-child(2) label::before {
    content: url("assets/img/jahresverbrauch-strom-tarifrechner.svg");
    height: 45px;
    width: auto;
    display: inline-block;
    position: absolute;
    left: 50px;
    z-index: 20;
    top: 50%;
    transform: translateY(-50%);
  }

  button {
    @include font-size($fontsize-L);
    line-height: 1.4;
    position: relative;
    padding: 30px 100px;
    transition: all 0.5s ease-in-out;
    outline: none;
    border: 0;
    color: #fff;
    width: 100%;
    height: 100%;
  }

  button:hover {
    background: $yellow;
  }

  .absx {
    top: -75px;
  }
}

#tarifresult {
  display: none;

  .tarif-disclaimer p {
    @include font-size($fontsize-XS);
    line-height: 14px;
  }

  h3 {
    margin-bottom: 0;
  }

  h3 + span {
    @include font-size($fontsize-S);
    line-height: 14px;
    display: inline-block;
  }

  .tarif-buttons {

    .outer-kontakt {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin-right: 15px;
      }

      p.kontakt {
        font-weight: 400;
        font-size: 2.5rem;
      }
    }
  }
}

.kundenportal iframe {
  width: 100%;
  min-height: 100%;
  border: 0;
  height: 600px;
}

.uzw-order {
  h4 {
    @include font-size($fontsize-S);
    font-weight: bold;
    line-height: 20px;
  }

  h3 {
    @include font-size($fontsize-XL2);
    line-height: 45px;
    margin: 20px 0 10px;
  }

  p {
    @include font-size($fontsize-S);
  }

  .gform_last_page_button {
    display: none;
  }

  .gfield_required {
    color: $green !important;
  }

  .gf_step_number {
    font-size: 18px !important;
    font-weight: 700 !important;
    background: #8FC1C2 !important;
    border: 0 !important;
    color: #fff !important;
  }

  .gf_step_completed .gf_step_number::before {
    background: $turkis !important;
    border: none !important;
    left: 0 !important;
    top: 0 !important;
  }

  .gf_step_active .gf_step_number {
    background: $turkis !important;
    border: none !important;
    color: $white !important;
  }

  .gfield {
    margin-bottom: 20px !important;
  }

  .ginput_container_date input {
    width: 100% !important;
  }

  .gform_page_fields {
    background: $lightturkis !important;
    padding: 40px;
  }

  .gf_angaben {
    float: right;
    @include font-size($fontsize-XS);
  }

  .gf_step_label {
    text-decoration: none !important;
    color: $black !important;
    font-size: 21px !important;
    line-height: 24px !important;
  }

  .gf_page_steps,
  .gsection {
    border: 0 !important;
  }

  .gfield_label {
    color: #8BADAD !important;
    font-weight: 400 !important;
  }

  .readless .read-more {
    margin-top: 5px;
    font-weight: 700 !important;
  }

  .truncate {
    height: 55px;
    overflow: hidden;
  }

  .sumgrid {
    grid-column-gap: 2%;
    grid-row-gap: 16px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(auto-fill, auto);
    width: 100%;

    .sumhalf {
      background: #fff;
      padding: 40px;

      a {
        font-weight: bold;
      }

      h3 {
        margin: 0 0 20px;
      }

      p {
        margin-bottom: 5px;
      }
    }
  }

  h3 {
    font-weight: 600;
  }
}

#gform_wrapper_14 {
  .gform_required_legend {
    display: none !important;
  }
}

@media all and (max-width: $size-XXL) {
  .preisbremse {
    width: 100%;
  }
}

@media all and (max-width: $size-XL2) {
  .preisbremse {
    width: 140%;
    margin-left: -20%;
    padding: 7%;
  }
}

@media all and (max-width: $size-XL) {
  .preisbremse {
    font-size: 1.7rem;
  }

  .tarifform {
    .absx {
      top: -25px;
    }

    input[type="text"] {
      @include font-size($fontsize-MS);
      line-height: 21px;
      padding: 10px 20px 10px 80px;
    }

    .g-33:first-child label::before,
    .g-33:nth-child(2) label::before {
      left: 30px;
    }

    button {
      padding: 30px;
    }
  }
}

@media all and (max-width: $size-L) {
}

@media all and (max-width: $size-M2) {
  .preisbremse {
    width: 150%;
    margin-left: -25%;
    padding: 5%;
  }

  .tarifform {
    .g-33:first-child label::before,
    .g-33:nth-child(2) label::before {
      display: none;
    }

    input[type="text"] {
      padding: 10px 20px;
    }

    button {
      @include font-size($fontsize-20);
      line-height: 24px;
    }
  }
}

@media all and (max-width: $size-M) {
  .tarif-box {
    width: 100%;
    margin-bottom: 10%;
  }

  .tarif-box + .g-5 {
    width: 75%;
    margin: 0 auto;
  }

  .preisbremse {
    width: 100%;
    margin-left: 0;
  }
}

@media all and (max-width: $size-S2) {
}

@media all and (max-width: $size-S) {
  .uzw-order .sumgrid .sumhalf {
    padding: 20px;
  }

  .preisbremse {
    width: 120%;
    margin-left: -10%;
  }

  #tarifwrap {
    padding-top: 150px;

    .form-group .g-3 {
      width: auto;
      margin-right: 15px;
    }

    .flex .g-4 {
      width: auto;
      max-width: 80%;
      margin-right: 0;
    }

    .form-tarife {
      .g-3 {
        margin-bottom: 20px;
      }

      .flex {
        justify-content: space-around;
      }
    }
  }

  .tarifform {
    .g-33 {
      width: 100%;
      height: 80px;
    }

    .g-33:first-child label::before,
    .g-33:nth-child(2) label::before {
      display: block;
    }

    .g-33:first-child input[type="text"] {
      border-right: 2px solid $hellgrau;
      border-bottom: 0;
    }

    input[type="text"] {
      text-align: center;
      padding: 30px 80px;
    }
  }
}

@media all and (max-width: $size-XS2) {
  .preisbremse {
    font-size: 1.4rem;
  }

  .tarifform {
    input[type="text"] {
      padding: 30px 30px 30px 80px;
    }

    button {
      padding: 20px;
    }
  }
}

@media all and (max-width: $size-XS) {
}


#field_17_149 .vario-strompreis {
  padding: 15px;
  background: #fff;
  font-size: 15px;
}

.tarif-result-head {
  margin-top: 140px;

  @media (max-width: 1025px) {
    margin-top: 120px;
  }
}
