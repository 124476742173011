//
// Footer
//

footer {
  background: $darkbluegrey;

  ul {
    @include ul-reset;
  }

  li {
    @include font-size($fontsize-S);
  }

  a,
  p {
    color: $white;
    @include font-size($fontsize-S);
  }

  strong {
    color: $yellow;
    font-weight: bold;
  }

  .linkbtn-no {
    padding: 23px 60px;
  }

  h5 {
    color: $white;
    font-weight: 300;
    line-height: 1.2;
    @include font-size($fontsize-L);
  }

  span {
    font-style: italic;
    font-weight: 600;
  }

  .g-3 {
    position: relative;
  }
}

.footer-legal > ul > li,
.footer-nav > ul > li {
  font-weight: 400;
  float: left;
  margin-right: 100px;
  width: 200px;
}

.footer-legal a,
.footer-nav li.menu-item-has-children > a {
  color: $yellow;
  font-weight: bold;
}

.footer-nav > ul > li > ul {
  margin-top: 5px;
}

.footer-nav > ul > li {
  margin-bottom: 40px;
}

.footer-legal > ul > li:last-child,
.footer-nav > ul > li:last-child {
  margin-right: 0;
}

.footer-nav ul ul > li {
  display: block;
  float: none;
  margin-bottom: 5px;
}

.footer-social {
  a:not(:last-child) {
    margin-right: 20px;
  }
}
@media all and (max-width:$size-XXL) {
  .footer-legal > ul > li,
  .footer-nav > ul > li {
    margin-right: 50px;
  }
}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  .footer-kontakt {
    .g-3 {
      width: 100%;
      margin-bottom: 60px;

      .mt-50,
      p {
        float: left;
        margin-right: 50px;
      }

      .mt-50 {
        margin-top: 0;
      }

      .mt-50:last-child {
        margin-right: 0;
      }
    }
  }

  .footer-kontakt .g-9 {
    width: 100%;
  }

  .footer-legal {
    margin-top: 50px;
  }
}
@media all and (max-width:$size-L) {
  .footer-nav > ul > li {
    margin-right: 40px;
  }
}
@media all and (max-width:$size-M2) {
  .footer-kontakt {
    .g-3 {
      margin-bottom: 40px;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-flow: row wrap;

      .mt-50,
      p {
        margin-right: 20px;
      }

      .footer-kbtn {
        margin-right: 0;
      }
    }
  }

  .footer-nav > ul {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  .footer-legal > ul > li,
  .footer-nav > ul > li {
    max-width: 160px;
    margin-right: 30px;
  }

  .footer-nav > ul > li:last-child,
  .footer-nav > ul > li:nth-child(3) {
    margin-right: 30px;
  }

  .footer-nav > ul > li:nth-child(1),
  .footer-nav > ul > li:nth-child(2),
  .footer-nav > ul > li:nth-child(3) {
    max-width: 210px;
  }
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  .footer-legal > ul > li,
  .footer-nav > ul > li,
  .footer-nav > ul > li:nth-child(1),
  .footer-nav > ul > li:nth-child(2),
  .footer-nav > ul > li:nth-child(3) {
    max-width: 150px;
  }
}
@media all and (max-width:$size-XS2) {
  .footer-legal > ul > li {
    margin-right: 20px;
  }

  .footer-legal > ul > li,
  .footer-nav > ul > li,
  .footer-nav > ul > li:nth-child(1),
  .footer-nav > ul > li:nth-child(2),
  .footer-nav > ul > li:nth-child(3) {
    max-width: 130px;
  }

  footer {
    .linkbtn-no {
      padding: 20px 40px;
    }
  }
}
@media all and (max-width:$size-XS) {
  .footer-nav > ul > li,
  .footer-nav > ul > li:nth-child(1),
  .footer-nav > ul > li:nth-child(2),
  .footer-nav > ul > li:nth-child(3) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 25px;
  }
}
