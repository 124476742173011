//
// Main Navigation
//

nav {
  position: relative;
}

.pagenav {
  padding-top: 32px;

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: block;
    float: left;
    padding-right: 50px;
  }

  li:last-child {
    padding-right: 0;
    padding-left: 10px;
  }

  a {
    color: $black;
    display: block;
    @include font-size($fontsize-M);
    font-weight: 400;
    text-transform: uppercase;
    transition: all 0.25s ease;
    position: relative;
  }

  .sub-title {
    display: block;
    @include font-size($fontsize-XL);
    float: left;
    color: $white;
    width: 100%;
    margin-bottom: 40px;
  }
}

.header-bg {
  background-color: rgba(119, 35, 48, 0.9);
  display: none;
  height: 400px;
  left: 0;
  position: absolute;
  right: 0;
  top: 112px;
  width: 100%;
  z-index: 1;
}

.pagenav ul > li a::after {
  opacity: 0;
  transition: all 0.25s ease-in-out;
}

.pagenav ul > li a:hover::after {
  content: '';
  display: inline-block;
  background: $yellow;
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 1;
  z-index: 30;
}

#menu-hauptmenu > li.current-menu-ancestor > a,
#menu-hauptmenu > li.current-menu-item > a {
  &::after {
    content: '';
    display: inline-block;
    background: $yellow;
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}

.pagenav .sub-menu a:hover::after {
  display: none;
}

.pagenav .sub-menu {
  display: none;
  position: absolute;
  left: 0;
  z-index: 110;
  padding: 40px 0 40px 443px;
  width: 100%;
  background: #fff;

  &::before {
    content: '';
    position: fixed;
    display: block;
    width: 100%;
    height: 412px;
    background: #fff;
    left: 0;
    top: 141px;
    z-index: 0;
  }

  li {
    display: inline-block;
    float: none;
    position: relative;
    margin: 0 50px 40px 0;
    width: 27%;
    padding: 0;
  }

  a {
    color: $black;
    display: inline-block;
    position: relative;
    @include font-size($fontsize-MS);
    padding: 0;
    text-align: left;
  }

  a:hover::after {
    content: '';
    display: inline-block;
    background: $yellow;
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: -2px;
    left: 0;
    opacity: 1;
  }

  .menu-item-has-children {
    position: relative;
  }

  .menu-item-has-children::after {
    width: 16px;
    height: 9px;
    right: -25px;
    top: 40%;
    content: ">";
    color: $black;
    display: inline-block;
    position: absolute;
  }

  .menu-item-has-children::after {
    content: ">";
    color: $black;
    position: absolute;
    right: 25px;
    top: 14px;
    width: 4px;
    height: 10px;
  }

  .menu-item-has-children:hover:after {
    color: $white;
  }
}

.pagenav .menu-item-has-children:hover > .sub-menu {
  display: block;
}

.menu-item-has-children > a {
  cursor: default;
}

#nav-icon {
  width: 40px;
  height: 18px;
  transform: rotate(0deg) translateX(-50%);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 9001;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #151515;
  border-radius: 0;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#header.fixed #nav-icon span {
  background: $black;
}

#nav-icon span:nth-child(1) {
  top: 0;
}

#nav-icon span:nth-child(2) {
  top: 8px;
}

#nav-icon span:nth-child(3) {
  top: 16px;
}

#nav-icon span:nth-child(4) {
  top: 16px;
}

#nav-icon.clicked span {
  background: $white;
}

#nav-icon.clicked span:nth-child(1) {
  transform: rotate(45deg);
  top: 8px;
}

#nav-icon.clicked span:nth-child(2) {
  display: none;
}

#nav-icon.clicked span:nth-child(3) {
  transform: rotate(-45deg);
  top: 8px;
}

#menu {
  --mm-color-background: #FCB21F;

  a {
    @include font-size($fontsize-M);
    font-weight: 700;
  }
}

#menu:not(.mm-menu) {
  display: none;
}

#menulink {
  position: absolute;
  top: 5px;
  right: 25px;
  color: #000 !important;
  @include font-size(35);
  white-space: nowrap;
  line-height: 1;
  display: none;
}

.nav-overlay {
  position: fixed;
  padding-left: 225px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(21, 21, 21, 1);
  z-index: 9000;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transform: translate(-50%, 0);

  .g-12 {
    height: 100vh;
  }

  .the--nav ul {
    perspective: 1200px;
    transform: translateY(-25%) rotateX(35deg);
    transition: transform 0.5s ease 0s, opacity 0.5s ease 0s;
  }

  .the--nav.open ul {
    opacity: 1;
    visibility: visible;
    transform: rotateX(0deg);
  }

  .vert {
    height: 33.333vh;
    position: relative;

    a {
      display: inline-block;
      width: 100%;
      height: 100%;
      float: left;
    }

    span {
      position: absolute;
      bottom: 20px;
      display: inline-block;
      text-transform: uppercase;
      color: $black;
      font-weight: 700;
    }
  }
}

.nav-overlay.open {
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease-in-out;
  transform: translate(0);
}

.head--nav {
  position: relative;
  display: block;
}

.head--nav ul {
  list-style: none;
  text-align: left;
  font-size: 60px;
  font-weight: bold;
}

.head--nav ul li a {
  color: $white;
  font-size: 70px;
}

.head--nav ul li a:hover {
  color: $green;
  text-decoration: underline;
}
@media all and (max-width:$size-XXL) {
  .pagenav li {
    margin-right: 30px;
    padding-right: 0;
  }

  .pagenav .sub-menu {
    padding: 40px 0 40px 335px;
  }

  .pagenav .sub-menu::before {
    height: 435px;
  }
}
@media all and (max-width:$size-XL2) {
  .pagenav {
    li {
      margin-right: 20px;
    }

    a {
      @include font-size($fontsize-20);
    }
  }

  .pagenav .sub-menu {
    padding: 40px 0 40px 210px;
  }

  .pagenav .sub-menu::before {
    height: 455px;
  }
}
@media all and (max-width:1180px) {
  #menulink {
    display: block;
  }

  .pagenav {
    display: none;
  }
}
@media all and (max-width:$size-L) {
  .navtrig {
    width: 23.584%;
    margin-top: 6px;

    #nav-icon {
      left: auto;
      right: 0;
    }
  }

  .nav-overlay {
    padding-left: 0;

    .head--nav {
      width: 100%;

      #menu {
        position: relative;
      }

      li a,
      ul {
        @include font-size($fontsize-L);
      }
    }
  }
}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
  #menulink {
    top: -1px;
  }
}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}
