/* Bild */
.bild50x2 {
  img {
    width: 100%;
  }
}

.slick-slider {
  .darklay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,rgba(0,0,0,0.2) 60%,rgba(0,0,0,0));
  }

  .galoverlay {
    position: absolute;
    z-index: 9999;
    left: 10%;
    bottom: 30%;

    p {
      @include font-size($fontsize-XL);
      line-height: 1.44;
      color: $white;

      strong {
        line-height: 1.25;
        @include font-size($fontsize-XXL);

        &:first-child {
          font-weight: 400;
        }
      }
    }
  }

  .eeg-stoerer {
    z-index: 9999;
    position: absolute;
    right: 10%;
    bottom: 20%;
    width: 215px;
    height: auto;
    object-fit: unset !important;
    min-height: auto !important;
    transform: rotate(14deg);
  }
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
  min-height: 50vh;
}

.slick-slide img {
  height: 100%;
  object-fit: cover;
}

.imglargepor img {
  min-height: 80vh;
}
@media all and (max-width:$size-XXL) {
  .slick-slider {
    .galoverlay {
      bottom: 8%;
    }
  }
}
@media all and (max-width:$size-XL2) {
  .slick-slider {
    .eeg-stoerer {
      width: 180px;
    }
    .galoverlay {
      bottom: 10%;
    }
  }
}
@media all and (max-width:$size-XL) {
  .slick-slider {
    .galoverlay {
      bottom: 15%;

      p {
        @include font-size($fontsize-L2);
        line-height: 1.44;
        color: $white;

        strong {
          line-height: 1.25;
          @include font-size($fontsize-XL60);

          &:first-child {
            font-weight: 400;
          }
        }
      }
    }

    .eeg-stoerer {
      width: 150px;
      right: 3%;
      bottom: 12%;
    }
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
  .slick-slider {
    .galoverlay {
      left: 5%;
    }

    .slick-slide {
      min-height: 450px;
    }

    .eeg-stoerer {
      width: 120px;
    }
  }
}
@media all and (max-width:$size-M) {
  .slick-slider {
    .galoverlay {
      p {
        @include font-size($fontsize-L2);
        line-height: 1.44;
        color: $white;

        strong {
          line-height: 1.25;
          @include font-size($fontsize-XL2);
        }
      }
    }
  }
}
@media all and (max-width:$size-S2) {}
@media all and (max-width:$size-S) {
  .imagebox .g-4:not(:last-child) {
    padding-bottom: 20px;
  }

  .slick-slider {
    .galoverlay {
      bottom: 10%;
      max-width: 80%;

      p {
        @include font-size($fontsize-20);
        line-height: 1.44;
        color: $white;

        strong {
          line-height: 1.25;
          @include font-size($fontsize-XL2);

          &:first-child {
            font-weight: 400;
          }
        }
      }

    }

    .eeg-stoerer {
      bottom: 9%;
    }
  }
}
@media all and (max-width:$size-XS2) {}
@media all and (max-width:$size-XS) {}

.hero-slider-link .linkbtn-yellow {
  z-index: 9999;
  border: 2px solid #FCB21F;
  background: #FCB21F;
  color: #FFFFFF;

  &:hover {
    border: 2px solid #2E7474;
  }
}
