//
// Global CSS
//
// apply a natural box layout model to all elements, but allowing components to change

html {
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}
// ----- HTML-Elemente -----

body {
  margin: 0;
  font-family: $baseFont;
}

html {
  margin-top: 0 !important;
  font-family: $baseFont;
  font-style: normal;
  font-weight: 400;
  font-size: 62.5%;
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

ol,
p,
table,
ul {
  color: $black;
  @include font-size($fontsize-L);
  line-height: 1.8;
}
/* Backgrounds Colors */
.wbg {
  background: $white;
}

.darkbg {
  background: $black;

  h1,
  h2,
  h3,
  h4,
  p,
  ul {
    color: $white;
  }
}
/* Global */
html {
  font-size: 62.5%;
}

body {
  @include font-size($fontsize-S);
  line-height: 2.1rem;
  height: 100%;
  color: $black;
  background-color: $white;
}

a {
  color: $green;
  text-decoration: none;
  display: inline-block;
}

a.linktxt {
  font-size: 25px;
  font-weight: 700;

  @media (max-width: 1024px) {
    font-size: 2.2rem !important;
  }

  @media (max-width: 850px) {
    font-size: 1.8rem !important;
  }

  @media (max-width: 600px) {
    font-size: 1.6rem !important;
  }
}

p,
ul {
  margin: 0 0 25px;
  hyphens: auto;
}

p del {
  text-decoration: none;
  @include font-size($fontsize-XS);
  line-height: 16px;
}

p:last-child,
ul:last-child {
  margin-bottom: 0;
}

ul {
  list-style: square outside;
}

ol {
  list-style-position: outside;
  list-style-type: decimal;
  list-style: none;
}

img {
  border-style: none;
}

.ofit {
  object-fit: cover;
  height: 100%;
}

.auto-width {
  width: auto !important;
  display: inline-block;
  max-width: none !important;
}

.gruen {
  ul {
    li {
      color: #FFFFFF;
    }
  }
}

// set some elements responsive

.ztop {
  z-index: 10;
  position: relative;
}

embed,
img,
object,
video {
  height: auto;
  max-width: 100%;
}

.coverfit img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.centered {
  text-align: center;
}

.left {
  text-align: left;
}

.centered {
  p {
    margin: 0 auto 25px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
// and for video

.video embed,
.video iframe,
.video object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
// ----- helper css classes -----

.clearfix {
  @include clearfix;
}

.hide-text {
  @include hide-text;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  @include list-inline;
}

.invisible {
  display: none !important;
}

.no-margin {
  margin: 0 !important;
}

.margin-auto {
  margin: 0 auto;
}

.right-float {
  float: right !important;
}

.vertical-align-sub {
  vertical-align: sub;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-middle {
  vertical-align: middle;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}
/* Helper classes */
/*
 * Image replacement
 */
.ir {
  background-color: transparent;
  border: 0;
  overflow: hidden;
  /* IE 6/7 fallback */
  *text-indent: -9999px;
}

.ir::before {
  content: "";
  display: block;
  width: 0;
  height: 100%;
}
/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.hidden {
  display: none !important;
  visibility: hidden;
}
/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
/*
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
/*
 * Hide visually and from screenreaders, but maintain layout
 */
.invisible {
  visibility: hidden;
}
/*
 * Clearfix: contain floats
 *
 */
.clearfix::after,
.clearfix::before {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix::after {
  clear: both;
}
/*
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
.clearfix {
  *zoom: 1;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
  ol,
  p,
  table,
  ul {
    @include font-size($fontsize-M);
    line-height: 1.7;
  }
}
@media all and (max-width:$size-L) {
  ol,
  p,
  table,
  ul {
    @include font-size($fontsize-MS);
    line-height: 1.5;
  }
}
@media all and (max-width:$size-M2) {}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
  ol,
  p,
  table,
  ul {
    @include font-size($fontsize-S);
    line-height: 1.4;
  }
}
@media all and (max-width:$size-S) {}
@media all and (max-width:$size-XS2) {
  .centered p {
    max-width: 100%;
  }
}
@media all and (max-width:$size-XS) {}
