//
// Base Grid
//

.container {
  margin: 0 auto;
  max-width: 1060px;
  width: 100%;
  position: relative;
}

.container.centered p {
  margin: 0 auto;
  text-align: center;
}

.container .centered {
  text-align: center;
}

.container-fullwidth::after {
  clear: both;
  content: " ";
  display: table;
}

.container_fullwidth {
  width: 100%;
  position: relative;
}

.container .col {
  display: block;
  float: left;
  box-sizing: border-box;
}

.container .col:first-child {
  margin: 0;
}

.ontop {
  z-index: 100;
  position: relative;
}

.flex {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-flow: row wrap;

  article img {
    width: 100%;
    height: 278px;
    object-fit: cover;
    float: left;
  }

  .mb-10,
  .mb-10:first-child {
    margin-bottom: 0.8vw;
  }
}

.flex.align-start {
  align-items: flex-start;
}

.flexcol {
  display: flex;
  flex-direction: column;
}

.flex-start {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.flex-start .g-4 {
  box-sizing: content-box;
}

.flex-end {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.container.flex-row {
  flex-direction: row;
}

.fourth .g-3 {
  margin-bottom: 20px;
}

.fourth::after {
  content: '';
  display: block;
  width: 49.056%;
}

.g-1 {
  width: 6.603%;
}

.mlr-1 {
  margin: 0 6.603% !important;
}

.mr-1 {
  margin-right: 6.603% !important;
}

.g-2 {
  width: 15.094%;
}

.g-20 {
  width: 20%;
}

.g-3 {
  width: 23.584%;
}

.g-33 {
  width: 33.333%;
}

.g-4 {
  width: 32.075%;
}

.g-40 {
  width: 40%;
}

.g-5 {
  width: 40.566%;
}

.g-6 {
  width: 49.056%;
}

.g-50 {
  width: 50%;
}

.g-60 {
  width: 60%;
}

.g-7 {
  width: 57.547%;
}

.g-8 {
  width: 66.037%;
}

.g-9 {
  width: 74.528%;
}

.g-10 {
  width: 83.018%;
}

.g-11 {
  width: 91.509%;
}

.g-12 {
  width: 100%;
}

.w50-16 {
  width: calc(50% - 8px);

  @media (max-width: 625px) {
    width: 100%;
  }
}

.colgap-16 {
  column-gap: 16px;
}

.colgap-40 {
  column-gap: 40px;
}

.pl-1 {
  padding-left: 1.887%;
}

.pl-2 {
  padding-left: 16.981%;
}

.pl-3 {
  padding-left: 25.471%;
}

.pl-4 {
  padding-left: 33.962%;
}

.pl-5 {
  padding-left: 42.453%;
}

.pl-6 {
  padding-left: 50.943%;
}

.pl-7 {
  padding-left: 59.434%;
}

.pl-8 {
  padding-left: 67.924%;
}

.pl-9 {
  padding-left: 76.415%;
}

.pl-10 {
  padding-left: 84.905%;
}

.pl-11 {
  padding-left: 93.396%;
}

.pr-1 {
  padding-right: 1.887%;
}

.pr-2 {
  padding-right: 8.49%;
}

.pr-3 {
  padding-right: 25.471%;
}

.pr-4 {
  padding-right: 33.962%;
}

.pr-5 {
  padding-right: 42.453%;
}

.pr-6 {
  padding-right: 50.943%;
}

.pr-7 {
  padding-right: 59.434%;
}

.pr-8 {
  padding-right: 67.924%;
}

.pr-9 {
  padding-right: 76.415%;
}

.pr-10 {
  padding-right: 84.905%;
}

.pr-11 {
  padding-right: 93.396%;
}

@media all and (min-width: $size-XXL) {
  .container {
    max-width: 1640px;
  }
}

@media all and (max-width: $size-XXL) {
  .container {
    max-width: 1280px;
  }
}

@media all and (max-width: $size-XL2) {
  .container {
    max-width: 1024px;
  }
}

@media all and (max-width: $size-XL) {
  .container {
    padding: 0 40px;
  }

  .g-40,
  .g-60 {
    width: 50%;
  }

  .g-6 .g-9 {
    width: 100%;
  }
}

@media all and (max-width: $size-L) {
  main > div.container-fullwidth:nth-child(3) {
    padding-top: 50px;
  }
  .page-id-8 .vorteile-auf-einen-blick .g-3 {
    width: 49%;
  }
  .page-id-8 .vorteile-auf-einen-blick .g-3:first-of-type,
  .page-id-8 .vorteile-auf-einen-blick .g-3:nth-of-type(2), {
    margin-bottom: 10px;
  }
}

@media all and (max-width: $size-M2) {
  .flex .g-4 {
    width: 49.056%;
    margin-bottom: 1.56vw;
  }

  .flex .g-4:first-child {
    margin-bottom: 1.56vw;
  }

  .flex.fourth::after {
    width: 32.075%;
  }

  .g-4.pr-2 {
    padding-right: 0;
  }

  .textbox .flex-start {
    justify-content: flex-start;
  }

  .flex-start {
    justify-content: space-between;

    .g-4 {
      width: 49.056%;
      margin: 0 !important;
    }
  }

  .mitarbeiter .mailko,
  .mitarbeiter .telko {
    margin-left: 5px;
  }

  .mlr-1 {
    margin: 0 !important;
  }
}

@media all and (max-width: $size-M) {
}

@media all and (max-width: $size-S2) {
  .container {
    padding: 0 30px;
  }
}

@media all and (max-width: $size-S) {
  .page-id-8 .vorteile-auf-einen-blick .g-3 {
    width: 100%;
  }
  .page-id-8 div:nth-of-type(13) div article {
    width: 100%;
  }
  .page-id-750 .bildtext .container .helltuerkis .plr-50-ptb-100 .imagebox,
  .page-id-738 .bildtext .container .helltuerkis .plr-50-ptb-100 .imagebox,
  .page-id-740 .bildtext .container .helltuerkis .plr-50-ptb-100 .imagebox{
    padding-top: 30px;
  }
  .page-id-750 .bildtext .container .helltuerkis .plr-50-ptb-100 .textbox,
  .page-id-741 .bildtext .container .helltuerkis .plr-50-ptb-100 .textbox,
  .page-id-740 .bildtext .container .helltuerkis .plr-50-ptb-100 .textbox,
  .page-id-738 .bildtext .container .helltuerkis .plr-50-ptb-100 .textbox {
    padding-bottom: 30px;
  }
  .page-id-772 .bildtext .container .helltuerkis,
  .page-id-5984 .bildtext .container .helltuerkis,
  .page-id-765 .bildtext .container .helltuerkis,
  .page-id-762 .bildtext .container .helltuerkis,
  .page-id-763 .bildtext .container .helltuerkis,
  .page-id-760 .bildtext .container .helltuerkis,
  .page-id-758 .bildtext .container .helltuerkis,
  .page-id-5695 .bildtext .container .helltuerkis,
  .page-id-753 .bildtext .container .helltuerkis,
  .page-id-750 .bildtext .container .helltuerkis,
  .page-id-741 .bildtext .container .helltuerkis,
  .page-id-740 .bildtext .container .helltuerkis,
  .page-id-738 .bildtext .container .helltuerkis,
  .page-id-732 .bildtext .container .helltuerkis,
  .page-id-731 .bildtext .container .helltuerkis,
  .page-id-729 .bildtext .container .helltuerkis,
  .page-id-722 .bildtext .container .helltuerkis,
  .page-id-721 .bildtext .container .helltuerkis,
  .page-id-720 .bildtext .container .helltuerkis,
  .page-id-719 .bildtext .container .helltuerkis,
  .page-id-718 .bildtext .container .helltuerkis,
  .page-id-1294 .bildtext .container .helltuerkis {
    width: 100%;
    margin-bottom: 50px !important;
  }
  .g-2 {
    width: 32.075%;
  }

  .container {
    padding: 0 25px;
  }

  .g-10,
  .g-3,
  .g-4,
  .g-40,
  .g-5,
  .g-50,
  .g-6,
  .g-60,
  .g-7,
  .g-9 {
    width: 100%;
  }
}

@media all and (max-width: $size-XS2) {
  .flex .g-4 {
    width: 100%;
    margin-bottom: 1.56vw;
  }
}

@media all and (max-width: $size-XS) {
}

.iconbox {
  .container {
    .centered {
      @media (max-width: 520px) {
        margin-bottom: 15px;
      }
    }
    .centered:last-of-type {
      @media (max-width: 520px) {
        margin-bottom: 0;
      }
    }
  }
}

.karriereql {
  .container {
    @media (max-width: 420px) {
      flex-direction: column-reverse;
    }
  }
}
