/*
Theme Name: ÜZW2021
Theme URI: https://www.uezw-energie.de
Description: ÜZW Energie
Version: 1.0
Author: rsk.
Author URI: https://www.raap-steinert.de
*/

// Normalize
@import "base/normalize";
// @import "base/webfonts";

// Mixins
@import "base/mixins";

// Base Settings
@import "base/settings";
@import "base/colors";
@import "base/webfonts";
@import "base/headings";
@import "base/grid";
@import "base/spacings";
@import "base/buttons";

// Global CSS
@import "base/global";

// Basic Layout
@import "header";
@import "nav-main";
@import "main";
@import "footer";
@import "search";
@import "archive-uezwpresse";

// Libraries
@import "lib/slick";
@import "lib/mmenu";

// Modules
@import "modules/bildtext";
@import "modules/bild";
@import "modules/text";
@import "modules/news";
@import "modules/service";
@import "modules/kontakt";
@import "modules/tarifrechner";
@import "modules/video";
@import "modules/tarifbox";
@import "modules/sondermodule";
@import "modules/formulare";
@import "modules/hero_video";
@import "modules/preisentwicklung";
@import "modules/pressebericht";
@import "modules/pressebericht-neueste";
