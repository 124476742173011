//
// Header
//

header {
  position: fixed;
  width: 100%;
  top: -1px;
  z-index: 9999;
  background: $white;
  height: 141px;
  transform: translateZ(0);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  #topbar {
    background: $darkbluegrey;
    color: $white;
    padding: 10px 0;
    text-align: right;

    a {
      color: $white;
    }

    a:hover {
      color: $green;
    }

    span {
      margin-left: 20px;
      text-transform: uppercase;
      @include font-size($fontsize-15);
    }

    .netzanmeldung-menu-item {

      @media (max-width: 1180px) {
        display: none;
      }
    }

    .hilfe-menu-item {

      @media (max-width: 730px) {
        display: none;
      }
    }

    .stoerungsdienst-menu-item {

      @media (max-width: 540px) {
        display: none;
      }
    }
  }

  .logo img {
    width: auto;
    max-height: 60px;
  }

  & + main {
    padding-top: 141px;
  }

  #navbar {
    padding: 20px 0 0;

    .container {
      @media (max-width: 1680px) {
        max-width: 96%;
      }
    }

    .pagenav a {
      @media (max-width: 1680px) {
        font-size: 2.0rem;
      }
    }

    .menu-item-7426 > a::before {
      content: url(assets/img/search-icon.svg);
      margin-right: 10px;
      position: absolute;
      left: -20px;
    }

    .netzanmeldung-mobile-item {
      display: none;
    }

    .hilfe-mobile-item {
      display: none;
    }

    .stoerungsdienst-menu-item {
      display: none;
    }
  }

  .search-box {
    display: none;
    position: absolute;
    right: 0;
    top: 80px;
    width: 676px;
    height: 124px;
    background-color: #EAF5F5;
    align-items: center; /* Vertikale Zentrierung */
    justify-content: center; /* Horizontale Zentrierung */

    form {

      input {
        width: 563px;
        height: 60px;
        padding: 0 30px 0 30px;
        background-image: url(assets/img/search-icon.svg);
        background-repeat: no-repeat;
        background-position-x: 510px;
        background-position-y: center;
        background-size: 20px;
        font-size: 18px;
        border: none;
      }

      input:focus-visible {
        outline: unset;
      }

      svg {
        margin-left: 20px;
      }
    }
  }
}

.hgmode {
  display: flex;
  min-height: 70vh;

  .g-12 {
    align-self: center;
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $black;
  opacity: 1 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $black;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $black;
  opacity: 1 !important;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $black;
  opacity: 1 !important;
}

@media all and (max-width: $size-XXL) {
}

@media all and (max-width: $size-XL2) {
}

@media all and (max-width: $size-XL) {
  .lgwrap {
    top: 20px;
    right: 80px;
  }

  header {
    height: 120px;

    .logo {
      width: 20%;
      float: left;
    }

    #navbar {
      padding: 15px 0 0;
    }

    & + main {
      padding-top: 120px;
    }
  }
}

@media all and (max-width: $size-L) {
}

@media all and (max-width: $size-M2) {
  header {
    .logo {
      width: 26%;
    }
  }
}

@media all and (max-width: $size-M) {
  .lgwrap {
    top: 10px;
  }

  header .logo img {
    width: 105px;
  }
}

@media all and (max-width: $size-S2) {
  header .logo {
    width: 35%;
  }

  header + main {
    padding-top: 70px;
  }
}

@media all and (max-width: 560px) {
  #topbar .container {
    span:last-child {
      display: none;
    }
  }
}

@media all and (max-width: $size-S) {
  header {
    height: 100px;

    #navbar {
      padding: 10px 0 0;
    }

    .logo img {
      width: 130px;
    }

    #topbar .container {
      span:first-child {
        margin-left: 0;
      }
    }

    & + main {
      padding-top: 100px;
    }
  }
}

@media all and (max-width: $size-XS2) {
  #topbar .container {
    span {
      margin-left: 5px;
    }
  }
}

@media all and (max-width: $size-XS) {
  #topbar .container {
    padding: 0 10px;
  }
}


.mm-listview {
  .netzanmeldung-mobile-item {
    display: none;

    @media (max-width: 1180px) {
      display: block;
    }
  }

  .hilfe-mobile-item {
    display: none;

    @media (max-width: 730px) {
      display: block;
    }
  }

  .stoerungsdienst-menu-item {
    display: none;

    @media (max-width: 540px) {
      display: block;
    }
  }
}
