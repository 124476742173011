//
// Buttons
//

a.gravitypdf-download-link,
a.linkbtn-no,
button.linkbtn {
	@include font-size($fontsize-L);
	position: relative;
	font-weight: 700;
	padding: 23px 100px;
	border-radius: 15px;
	transition: all 0.5s ease-in-out;
}

a.gravitypdf-download-link {
	color: $white;
	background: $green;
}

a.linkbtn {
	@include font-size($fontsize-L);
	position: relative;
	font-weight: 700;
	padding: 23px 100px;
	border-radius: 15px;
	border: 2px solid $green;
	transition: all 0.5s ease-in-out;
}

a.gravitypdf-download-link:hover,
a.linkbtn-no:hover,
a.linkbtn:hover,
button.linkbtn:hover {
	color: $white;
	background: $green;

	.linkbubble {
		transform: scale(1.10);
	}
}

.textlink {
	text-transform: uppercase;
	font-weight: 700;
	color: $green;
	position: relative;
	transition: background 0.35s ease-in-out;
}

.textlink:hover {
	background: $green;
}

.ybg .textlink:hover {
	background: $green;
	color: $green;
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {}
@media all and (max-width:$size-XL) {
	a.linkbtn {
		padding: 23px 40px;
	}
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
	a.linkbtn {
		@include font-size($fontsize-M);
		padding: 23px 60px;
	}
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
	a.linkbtn {
		@include font-size($fontsize-MS);
	}
}
@media all and (max-width:$size-S) {
	.linkbtn.mt-30 {
		margin-top: 0;
	}

	a.linkbtn {
		.linkbubble {
			width: 5rem;
			height: 5rem;

			&:before {
				margin-top: 1.80rem;
				margin-left: -0.4rem;
			}
		}
	}
}
@media all and (max-width:$size-XS2) {
	a.linkbtn-no {
		@include font-size($fontsize-MS);
		padding: 23px 60px;
	}
}
@media all and (max-width:$size-XS) {}
