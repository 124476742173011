//
// Headings
//

h1 {
  position: relative;
  hyphens: auto;

  &:before {
    content: "";
    position: absolute;
    display: inline-block;
    top: -30px;
    left: 0;
    width: 300px;
    height: 10px;
    background: $green;
  }

  &:after {
    content: "";
    position: absolute;
    display: inline-block;
    top: -50px;
    left: 0;
    width: 300px;
    height: 10px;
    background: $yellow;
  }
}

h1.withoutline {
  text-align: inherit !important;

  &:before {
    content: none;
  }

  &:after {
    content: none;
  }
}

h2.lookslikeh1,
h3.lookslikeh1,
h4.lookslikeh1,
h5.lookslikeh1,
h6.lookslikeh1 {
  margin: 0 0 30px;
  color: $black;
  @include font-size($fontsize-XXL);
  font-style: normal;
  font-weight: 400;
  line-height: 10.5rem;
  letter-spacing: -0.1rem;

  strong {
    font-weight: 700;
  }

  @media all and (max-width:$size-XL) {
    font-size: 5.5rem !important;
    line-height: 7rem !important;
    margin: 0 0 20px !important;
  }

  @media all and (max-width:$size-M2) {
    font-size: 6rem !important;
    line-height: 8rem !important;
  }

  @media all and (max-width:$size-S2) {
    font-size: 6rem !important;
    line-height: 6rem !important;
    margin: 0 0 20px !important;
  }

  @media all and (max-width:$size-S) {
    font-size: 45rem !important;
    line-height: 4.5rem !important;
  }
}

h1,
h2 {
  margin: 0 0 30px;
  color: $black;
  @include font-size($fontsize-XXL);
  font-style: normal;
  font-weight: 400;
  line-height: 10.5rem;
  letter-spacing: -0.1rem;

  strong {
    font-weight: 700;
  }
}

h1 + h2,
h2 + h3 {
  @include font-size($fontsize-XL);
  color: $black;
  hyphens: auto;
  font-style: normal;
  font-weight: 400;
  line-height: 4.5rem;
  text-transform: none;
}

h2,
h3,
h4,
h5 {
  margin: 0 0 20px;
  letter-spacing: -0.04rem;
  hyphens: auto;
}

h3 {
  color: $black;
  font-weight: 400;
  @include font-size($fontsize-XL60);
  line-height: 8.5rem;
  margin-bottom: 30px;
}

h4 {
  color: $black;
  font-weight: 400;
  @include font-size($fontsize-XL2);
  line-height: 4.5rem;
  margin-bottom: 15px;
}

h5 {
  color: $black;
  font-weight: 400;
  @include font-size($fontsize-L);
  line-height: 3.3rem;
  margin-bottom: 10px;
}

h6 {
  color: $black;
  font-weight: 400;
  @include font-size($fontsize-S);
  line-height: 1.6rem;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.bigoutline {
  @include font-size($fontsize-XXL);
  text-transform: none;
  color: $white;
  text-shadow: -1px 1px 0 #D0D0D0, 1px 1px 0 #D0D0D0, 1px -1px 0 #D0D0D0, -1px -1px 0 #D0D0D0;
  font-weight: 800;
  z-index: 5;
  position: absolute;
  right: -60%;
  top: 0;
  animation: anilr 12s infinite;
}

p.kontakt {
  @include font-size($fontsize-XL2);
  margin-left: 25px;
  line-height: 1;
  float: left;
  display: inline-block;
}

.lightadd {
  color: $bluegrey;
  @include font-size($fontsize-M);
}
@keyframes anilr {
  0%,
  100% {
    right: -60%;
  }

  50% {
    right: -40%;
  }
}
@media all and (max-width:$size-XXL) {}
@media all and (max-width:$size-XL2) {
  h4 {
    @include font-size($fontsize-L);
    line-height: 3.2rem;
  }
}
@media all and (max-width:$size-XL) {
  h1,
  h2,
  h3 {
    hyphens: auto;
  }

  h1,
  h2 {
    @include font-size($fontsize-55);
    line-height: 7rem;
    margin: 0 0 20px;
  }

  h1::after,
  h1::before {
    height: 7px;
  }

  h3 {
    @include font-size($fontsize-XL);
    line-height: 6rem;
  }
}
@media all and (max-width:$size-L) {}
@media all and (max-width:$size-M2) {
  h1 + h2,
  h2 + h3 {
    @include font-size($fontsize-L2);
    line-height: 35px;
  }

  h1,
  h2 {
    @include font-size($fontsize-XL60);
    line-height: 8rem;
  }

  h3 {
    @include font-size($fontsize-XL2);
    line-height: 5.5rem;
  }
}
@media all and (max-width:$size-M) {}
@media all and (max-width:$size-S2) {
  h1,
  h2 {
    @include font-size($fontsize-XL60);
    line-height: 6rem;
    margin: 0 0 20px;
  }

  .bildtext h2 {
    @include font-size($fontsize-XL);
    line-height: 4.5rem;
    margin: 0 0 20px;
    word-break: break-word;
  }

  h1 + h2,
  h2 + h3 {
    @include font-size($fontsize-L);
    line-height: 24px;
  }
}
@media all and (max-width:$size-S) {
  h1 {
    margin-top: 100px;
  }

  p.kontakt {
    @include font-size($fontsize-L);
    max-width: 80%;
  }

  h3 {
    @include font-size($fontsize-L2);
    line-height: 4.5rem;
  }

  h1,
  h2 {
    @include font-size($fontsize-XL);
    line-height: 4.5rem;
  }

  h1 + h2,
  h2 + h3 {
    @include font-size($fontsize-L);
    line-height: 24px;
  }
}
@media all and (max-width:$size-XS) {}

.bold {
  font-weight: 700;
}
