.search-burgermenu {
  padding-top: 25px;
  padding-bottom: 25px;

  form {
    display: flex;
  }

  input {
    width: 100%;

    &:focus {
      background: #ffff00;
    }
  }

  button {
    border-radius: 0;
    border: none;
  }
}

.search-header {
  margin-top: -2px;

  input {
    height: unset;
    padding: 0;
    color: #ffffff;

    &::placeholder {
      color: white;
    }

    &:focus {
      background: transparent;
    }
  }

  svg {
    height: 12px;
    fill: white;
  }
}

.top-menu:after {
  content: '|';
  padding-right: 10px;
  padding-left: 10px;
  font-size: 1.4rem;
}

.search-field {
  color: #000000 !important;
}

.search-no-results {
  .content-area.inner {
    padding-top: 100px;
  }

  .page-content {
    margin: 0;
  }

  .search-loadmore {
    display: none;
  }
}

.search-page {
  position: relative;
  padding: 45px 0;

  @media screen and (max-width:820px) {
    padding: 45px 40px;
  }

  form {

    input {
      width: 563px;
      height: 60px;
      padding: 0 30px 0 30px;
      background-image: url(assets/img/search-icon.svg);
      background-repeat: no-repeat;
      background-position-x: 550px;
      background-position-y: center;
      background-size: 20px;
      font-size: 18px;
      border: none;
    }
    input:focus-visible {
      outline: unset;
    }
    svg {
      margin: 0 20px;
      height: 21px;
    }
  }

  .search-field-page {
    width: 100%;
    max-width: 600px;
    border: 0;
    height: 60px;
    font-size: 18px;
    padding: 0 30px 0 30px;
  }

  .search-field-page:focus-visible {
    outline: unset;
  }

  #delete-button {
    position: absolute;
    right: 75px;
    top: 17px;
  }

  button {
    border-left: 1px solid black;
    border-top: none;
    border-right: none;
    border-bottom: none;
    background: white;
    border-radius: 0;
    height: 54px;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.search-results {
  .content-area.inner {
    padding-top: 100px;

    article {
      background: #FFFFFF;
      padding: 25px;
      margin-bottom: 10px;

      h2 {
        color: #1c1c1c;
        //.font-size(3.2);
        margin-bottom: 15px;
      }

      * {
        padding: 0;
        margin: 0;
      }
    }
  }

  .search-loadmore {
    text-align: center;
    margin-bottom: 25px;

    button {
      font-size: 2.5rem;
      font-weight: 700;
      color: #FFFFFF;
      border: none;
      border-radius: 15px;
      padding: 23px 60px;
      position: relative;
    }
  }

  .linkarrow {
    height: 10px;
  }

  .entry-footer {
    text-align: right;
    top: 10px;
    @media screen and (max-width: 520px) {
      text-align: center;
    }

    a {
      position: relative;
    }
  }
}

.searchmain {
  @media screen and (max-width: 820px) {
    margin-top: 119px;
  }
  @media screen and (max-width: 520px) {
    margin-top: 100px;
  }

  .container-fullwidth {
    background-color: #EAF5F5;
    margin-bottom: 96px;
  }

  .searchresults {
    font-size: 40px;

    @media screen and (max-width: 520px) {
      font-size: 30px;
    }
  }
}

.search-results {
  .entry-footer {
    text-align: left;
    .textlink {
      text-transform: none;
    }
  }
}

.search-result {
  background-color: #EAF5F5;
  margin-bottom: 10px;
  padding: 64px;
  @media screen and (max-width: 520px) {
    padding: 64px 25px;
    margin-left: -25px;
    margin-right: -25px;
  }

  .entry-header {
    font-weight: 600;
    margin-bottom: 30px;
  }

  .entry-footer {
    .readmore-search {
      color: #FFFFFF;
    }
  }
}
.search-result:nth-child(odd) {
  background-color: unset;
}

.search-highlight {
  color: #1c1c1c;
  background-color: #FCB21F;
}

#search-input2 {
  background-image: none;
}

.search-close2 {
  position: relative;
  right: 60px;
  top: 2px;
}

.search-box {
  button {
    right: 75px;
    position: relative;
    border: none;
    background-color: unset;
  }
}

.search-page {
  button {
    position: relative;
    right: 67px;
    height: 60px;
    display: inline-block;
    top: 2px;
  }
}
