/* poppins-200 - latin */
@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 200;
  src: url('assets/fonts/poppins-v15-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/poppins-v15-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/poppins-v15-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/poppins-v15-latin-200.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/poppins-v15-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/poppins-v15-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
}

/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/poppins-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/poppins-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/poppins-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/poppins-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/poppins-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/poppins-v15-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}


/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/poppins-v15-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/poppins-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/poppins-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/poppins-v15-latin-600.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/poppins-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/poppins-v15-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}


/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/poppins-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('assets/fonts/poppins-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/poppins-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/poppins-v15-latin-700.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/poppins-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/poppins-v15-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
