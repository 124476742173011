//
// Default Project Settings
//
// base inner page content width
$maxPageWidth: 2560px; // base font
$baseFont: 'Poppins', sans-serif; // font sizes
$fontsize-FA: 125;
$fontsize-XXL: 85;
$fontsize-XL70: 70;
$fontsize-XL60: 60;
$fontsize-55: 55;
$fontsize-XL: 45;
$fontsize-XL2: 40;
$fontsize-L2: 30;
$fontsize-L: 25;
$fontsize-M: 22;
$fontsize-20: 20;
$fontsize-MS: 18;
$fontsize-S: 16;
$fontsize-15: 15;
$fontsize-XS: 14;
//breakpoints
$size-XXL2: 1920px;
$size-XXL: 1680px;
$size-XL3: 1366px;
$size-XL2: 1280px;
$size-XL1: 1180px;
$size-XL: 1024px;
$size-L2: 960px;
$size-L: 850px;
$size-M2: 768px;
$size-M: 736px;
$size-S2: 600px;
$size-S: 480px;
$size-XS2: 380px;
$size-XS: 320px;
